import React from 'react';
import FormularioContacto from '../components/formularios/FormularioContacto';

function Contacto() {
  return (
    <main className="w-full p-0 m-0 flex flex-col items-center content-center overflow-hidden relative">
      <div className="w-full group h-32 md:h-56 p-6 flex justify-center items-end bg-green-50 relative overflow-hidden">
        <h1 className="z-10 text-xl sm:text-2xl md:text-4xl lg:text-5xl font-extrabold text-white font-sans group-hover:-translate-y-3 transition duration-500 ease-in-out">
          CONTACTANOS
        </h1>
        <img
          src="https://dps2wxsijib8x.cloudfront.net/atst/BannerPagina.webp"
          alt="Logo Andes Tec"
          className="object-cover w-full h-full absolute top-0 left-0 right-0 bottom-0 z-0"
        />
        <div className="absolute top-0 left-0 right-0 bottom-0 z-0 bg-blue-950 opacity-70" />
      </div>
      <div className="mapa relative h-56 w-full overflow-hidden border">
        <iframe
          title="Mapa de Google de una ubicación específica"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2364.269770326839!2d-70.56576781928655!3d-33.4075906636294!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662cedcfd4b7adf%3A0xffe301ec35abcdfa!2sAv.%20Apoquindo%206410%2C%20Oficina%20507%2C%208320000%20Las%20Condes%2C%20Regi%C3%B3n%20Metropolitana!5e0!3m2!1ses-419!2scl!4v1706812148519!5m2!1ses-419!2scl" 
          style={{ border: '0', position: 'absolute' }}
          allowfullscreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          className="w-full h-full"
        />
      </div>
      <div className="w-full flex flex-col md:flex-row content-center justify-start p-10 items-start overflow-hidden">
        <div>
          <h2 className="uppercase color-azul-3 font-extrabold text-xl border-l-4 pl-4 border-green-950 my-4">
            Nuestras redes de contacto
          </h2>
          <p className="color-gris-4 text-sm md:text-base my-2">
          En ATST estamos comprometidos con ofrecer un servicio de calidad. Te invitamos a contactarnos a través de nuestros diversos canales de comunicación.
          </p>
        </div>
        <div className="md:pl-10 grid grid-cols-2 gap-4 md:flex md:flex-wrap">
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            href="mailto:contacto@atst.cl"
            target="_blank"
            rel="noreferrer"
            className="flex content-center items-center text-xl font-semibold text-green-950 px-4 cursor-pointer border-white hover:border-green-900 hover:scale-105 transition duration-500 rounded-md border-4 shadow-md py-2 md:mx-4 md:my-4"
          >
            <ion-icon
              className="text-4xl text-green-950 hover:text-green-900 transition-colors duration-300 ease-in-out"
              name="mail-outline"
            />
            <div className="flex flex-col">
              <span className="ml-2">Correo</span>
              <span className="ml-2 text-xs text-gray-500">
              contacto@atst.cl
              </span>
            </div>
          </a>
          <div
            id="phone-link"
            className="flex content-center items-center text-xl font-semibold text-green-950 px-4 cursor-pointer border-white hover:border-green-900 hover:scale-105 transition duration-500 rounded-md border-4 shadow-md py-2 md:mx-4 md:my-4"
          >
            <ion-icon
              className="text-4xl text-green-950 hover:text-green-900 transition-colors duration-300 ease-in-out"
              name="call-outline"
            />
            <div className="flex flex-col">
              <span className="ml-2">Teléfono</span>
              <span id="phone-number" className="ml-2 text-xs text-gray-500">
                +569 3388 3163
              </span>
            </div>
          </div>
          <a
            rel="noreferrer"
            href="https://maps.app.goo.gl/KxXxkuKYo3CJG88F8"
            className="flex content-center items-center text-xl font-semibold text-green-950 px-4 cursor-pointer border-white hover:border-green-900 hover:scale-105 transition duration-500 rounded-md border-4 shadow-md py-2 md:mx-4 md:my-4"
            target="_blank"
          >
            <ion-icon
              className="text-4xl text-green-950 hover:text-green-900 transition-colors duration-300 ease-in-out"
              name="location-outline"
            />
            <div className="flex flex-col">
              <span className="ml-2">Dirección</span>
              <span className="ml-2 text-xs text-gray-500">
                Av. Apoquindo 6410 oficina 507, Las Condes, Región Metropolitana
              </span>
            </div>
          </a>
        </div>
      </div>
      <div className="px-10 pb-10 w-full relative">
        <div className=" p-6 mt-4 border-4 border-gray-100 hover:border-gray-200 rounded-md hover:shadow-lg overflow-hidden transition duration-500 relative z-10">
          <h3 className="font-extrabold text-start md:text-xl text-white uppercase relative z-10">
            Formulario de contacto
          </h3>
          <p className="text-start text-sm md:text-base text-gray-100 my-3 relative z-10 font-semibold">
            Si tienes alguna duda o consulta, no dudes en contactarnos a través
            de nuestro formulario de contacto. Te responderemos a la brevedad.
          </p>
          <div className="w-full relative z-10">
            <FormularioContacto />
          </div>
          <img
            src="https://dps2wxsijib8x.cloudfront.net/atst/BannerContacto.png"
            alt="Logo Andes Tec"
            className="object-cover w-full h-full absolute top-0 left-0 right-0 bottom-0 z-0"
          />
          <div className="absolute top-0 left-0 right-0 bottom-0 z-0 bg-green-950 opacity-70" />
        </div>
      </div>
    </main>
  );
}

export default Contacto;
