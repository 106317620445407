import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';
import confetiAnimation from '../../assets/confetti.json';
import LottieAnimation from '../../hooks/getLottie';

function Producto({ titulo, estado, descripcion, imagenUrl }) {
  const sanitizedHTML = DOMPurify.sanitize(descripcion);
  return (
    <Link
      to={`/producto/${titulo}`}
      className={`group relative w-full mb-4 border-2 transition duration-500 hover:border-blue-900 rounded-md overflow-hidden ${
        estado === 'Agotado' ? 'cursor-not-allowed' : 'cursor-pointer'
      }`}
    >
      <div className="h-32 md:h-48 relative">
        <img
          src={imagenUrl}
          className="w-full h-full object-contain"
          alt={titulo}
        />
        <div className="bg-green-950 opacity-0 group-hover:opacity-50 transition duration-300 absolute top-0 left-0 right-0 bottom-0 z-0" />
        <div className="z-10 opacity-0 group-hover:opacity-100 absolute bottom-0 p-2">
          <h4 className="text-white font-bold">{titulo}</h4>
          <div className="h-20 overflow-scroll overflow-x-hidden scroll-container">
            {/* eslint-disable */}
            <p
              className="text-shadow text-white text-xs"
              dangerouslySetInnerHTML={{ __html: sanitizedHTML }}
            />
            {/* eslint-enable */}
          </div>
        </div>
      </div>
      {estado === 'Proximamente' && (
        <>
          <div className="bg-green-100 absolute top-2 right-0 border-green-300 border-l border-t border-b rounded-l px-3">
            <h3 className="uppercase text-green-600 font-semibold z-10 text-xs md:text-sm">
              {estado}
            </h3>
          </div>
          <div className="absolute inset-0 w-full h-full z-0">
            <LottieAnimation animationData={confetiAnimation} />
          </div>
        </>
      )}
      <div
        className={`w-full py-2 md:py-0 md:h-10 z-10 relative flex justify-center items-center content-center ${
          estado === 'Agotado'
            ? 'bg-gray-400 text-gray-600'
            : 'fondo-formulario text-white'
        }`}
      >
        <h5 className="text-xs md:text-base font-extrabold uppercase">{titulo}</h5>
      </div>
    </Link>
  );
}

Producto.propTypes = {
  titulo: PropTypes.string.isRequired,
  estado: PropTypes.string.isRequired,
  descripcion: PropTypes.string.isRequired,
  imagenUrl: PropTypes.string.isRequired,
};

export default Producto;
