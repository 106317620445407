import React, { Fragment, useState } from 'react';
import { Dialog, Disclosure, Menu, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';

import {
  ChevronDownIcon,
  FilterIcon,
  MinusSmIcon,
  PlusSmIcon,
  ViewGridIcon,
  ViewListIcon,
} from '@heroicons/react/solid';
import ListadoProductos from '../components/productos/ListadoProductos';

const sortOptions = [
  { name: 'Mas populares', href: '#' },
  { name: 'Más reciente', href: '#' },
];

const filters = [
  {
    id: 'motorola',
    name: 'Motorola',
    options: [
      {
        value: 'radios-portatiles',
        label: 'Radios Portátiles',
        categoria_id: 1,
        checked: false,
      },
      {
        value: 'radios-moviles',
        label: 'Radios Móviles',
        categoria_id: 2,
        checked: false,
      },
      {
        value: 'repetidoras',
        label: 'Repetidoras',
        categoria_id: 3,
        checked: false,
      },
      {
        value: 'accesorios',
        label: 'Accesorios',
        categoria_id: 4,
        checked: false,
      },
    ],
  },
  {
    id: 'hytera',
    name: 'Hytera',
    options: [
      {
        value: 'radios-portatiles',
        label: 'Radios Portátiles',
        categoria_id: 5,
        checked: false,
      },
      {
        value: 'radios-moviles',
        label: 'Radios Móviles',
        categoria_id: 6,
        checked: false,
      },
      {
        value: 'repetidoras',
        label: 'Repetidoras',
        categoria_id: 7,
        checked: false,
      },
      {
        value: 'accesorios',
        label: 'Accesorios',
        categoria_id: 8,
        checked: false,
      },
      {
        value: 'equipos-poc',
        label: 'Equipos POC',
        categoria_id: 9,
        checked: false,
      },
      {
        value: 'bodycams',
        label: 'Bodycams',
        categoria_id: 10,
        checked: false,
      },
    ],
  },
  {
    id: 'kenwood',
    name: 'Kenwood',
    options: [
      {
        value: 'radios-portatiles',
        label: 'Radios Portátiles',
        categoria_id: 11,
        checked: false,
      },
      {
        value: 'radios-moviles',
        label: 'Radios Móviles',
        categoria_id: 12,
        checked: false,
      },
      {
        value: 'repetidoras',
        label: 'Repetidoras',
        categoria_id: 13,
        checked: false,
      },
      {
        value: 'accesorios',
        label: 'Accesorios',
        categoria_id: 14,
        checked: false,
      },
    ],
  },
  {
    id: 'sepura',
    name: 'Sepura',
    options: [
      {
        value: 'radios-portatiles',
        label: 'Radios Portátiles',
        categoria_id: 15,
        checked: false,
      },
      {
        value: 'radios-moviles',
        label: 'Radios Móviles',
        categoria_id: 16,
        checked: false,
      },
      {
        value: 'accesorios',
        label: 'Accesorios',
        categoria_id: 17,
        checked: false,
      },
    ],
  },
  {
    id: 'txpro',
    name: 'TXPRO',
    options: [
      {
        value: 'radios-portatiles',
        label: 'Radios Portátiles',
        categoria_id: 18,
        checked: false,
      },
      {
        value: 'accesorios',
        label: 'Accesorios',
        categoria_id: 19,
        checked: false,
      },
    ],
  },
  {
    id: 'wings',
    name: 'Wings',
    options: [
      {
        value: 'radios-portatiles',
        label: 'Radios Portátiles',
        categoria_id: 20,
        checked: false,
      },
    ],
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Productos() {
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [selectedMobileFilters] = useState({});
  const [sort, setSort] = useState('Más populares');
  const [vista, setVista] = useState('grid');

  // Obtenemos las categorias seleccionadas
  // eslint-disable-next-line camelcase
  const updateFilters = (value, sectionId, categoria_id) => {
    const newFilters = [...selectedFilters]; // Creando una copia del estado anterior

    const existingFilterIndex = newFilters.findIndex(
      (filter) => filter.id === sectionId,
    );

    if (existingFilterIndex !== -1) {
      const existingFilter = { ...newFilters[existingFilterIndex] };

      if (!existingFilter.values) {
        existingFilter.values = [];
      }

      const valueIndex = existingFilter.values.findIndex(
        (item) => item.value === value,
      );

      if (valueIndex > -1) {
        existingFilter.values.splice(valueIndex, 1);
      } else {
        // eslint-disable-next-line camelcase
        existingFilter.values.push({ value, categoria_id });
      }

      // Si el filtro ya no tiene más valores, lo eliminamos
      if (existingFilter.values.length === 0) {
        newFilters.splice(existingFilterIndex, 1);
      } else {
        newFilters[existingFilterIndex] = existingFilter;
      }
    } else {
      // eslint-disable-next-line camelcase
      newFilters.push({ id: sectionId, values: [{ value, categoria_id }] });
    }

    setSelectedFilters(newFilters);
  };

  // Seleccionamos la vista a mostrar
  const updateVista = () => {
    setVista((prevVista) => (prevVista === 'grid' ? 'list' : 'grid'));
  };

  return (
    <div className="bg-white">
      <div className="w-full group h-32 md:h-56 p-6 flex justify-center items-end bg-blue-50 relative overflow-hidden">
        <h1 className="z-10 text-xl sm:text-2xl md:text-4xl lg:text-5xl font-extrabold text-white font-sans group-hover:-translate-y-3 transition duration-500 ease-in-out">
          NUESTROS PRODUCTOS
        </h1>
        <img
          src="https://dps2wxsijib8x.cloudfront.net/atst/BannerPagina.webp"
          alt="Logo Andes Tec"
          className="object-cover w-full h-full absolute top-0 left-0 right-0 bottom-0 z-0"
        />
        <div className="absolute top-0 left-0 right-0 bottom-0 z-0 bg-blue-950 opacity-70" />
      </div>
      <div>
        {/* Mobile filter dialog */}
        <Transition.Root show={mobileFiltersOpen} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 flex z-40 lg:hidden"
            onClose={setMobileFiltersOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="ml-auto relative max-w-xs w-full h-full bg-white shadow-xl py-4 pb-12 flex flex-col overflow-y-auto">
                <div className="px-4 flex items-center justify-between">
                  <h2 className="text-lg font-medium text-gray-900">Filters</h2>
                  <button
                    type="button"
                    className="-mr-2 w-10 h-10 bg-white p-2 rounded-md flex items-center justify-center text-gray-400"
                    onClick={() => setMobileFiltersOpen(false)}
                  >
                    <span className="sr-only">Close menu</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                {/* Filters */}
                <form className="mt-4 border-t border-gray-200">
                  {filters.map((section) => (
                    <Disclosure
                      as="div"
                      key={section.id}
                      className="border-t border-gray-200 px-4 py-6"
                    >
                      {({ open }) => (
                        <>
                          <h3 className="-mx-2 -my-3 flow-root">
                            <Disclosure.Button className="px-2 py-3 bg-white w-full flex items-center justify-between text-gray-400 hover:text-gray-500">
                              <span className="font-medium text-gray-900">
                                {section.name}
                              </span>
                              <span className="ml-6 flex items-center">
                                {open ? (
                                  <MinusSmIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                ) : (
                                  <PlusSmIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                )}
                              </span>
                            </Disclosure.Button>
                          </h3>
                          <Disclosure.Panel className="pt-6">
                            <div className="space-y-6">
                              {section.options.map((option, optionIdx) => (
                                <div
                                  key={option.value}
                                  className="flex items-center"
                                >
                                  <input
                                    id={`filter-mobile-${section.id}-${optionIdx}`}
                                    name={`${section.id}[]`}
                                    defaultValue={option.value}
                                    type="checkbox"
                                    checked={
                                      selectedMobileFilters[
                                        `${section.id}-${option.value}`
                                      ] || false
                                    }
                                    className="h-4 w-4 border-gray-300 rounded text-indigo-600 focus:ring-indigo-500"
                                    onChange={() => {
                                      updateFilters(
                                        option.value,
                                        section.id,
                                        option.categoria_id,
                                        section,
                                      );
                                    }}
                                  />
                                  <label
                                    htmlFor={`filter-mobile-${section.id}-${optionIdx}`}
                                    className="ml-3 min-w-0 flex-1 text-gray-500"
                                  >
                                    {option.label}
                                  </label>
                                </div>
                              ))}
                            </div>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  ))}
                </form>
              </div>
            </Transition.Child>
          </Dialog>
        </Transition.Root>
        <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="relative z-10 flex items-baseline justify-between pt-10 pb-6 border-b border-gray-200">
            <div className="hidden md:flex items-center justify-center text-gray-500 text-base font-bold uppercase p-2">
              CATEGORÍAS
            </div>
            {/* Ordenar y ver como grid */}
            <div className="flex items-center">
              <Menu as="div" className="relative inline-block text-left">
                <div>
                  <Menu.Button className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                    Ordenar
                    <ChevronDownIcon
                      className="flex-shrink-0 -mr-1 ml-1 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                  </Menu.Button>
                </div>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="origin-top-right absolute right-0 mt-2 w-40 rounded-md shadow-2xl bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                      {sortOptions.map((option) => (
                        <Menu.Item key={option.name}>
                          {({ active }) => (
                            <a
                              href={option.href}
                              className={classNames(
                                sort === option.name
                                  ? 'font-medium text-gray-900'
                                  : 'text-gray-500',
                                active ? 'bg-gray-100' : '',
                                'block px-4 py-2 text-sm',
                              )}
                              onClick={() => setSort(option.name)}
                            >
                              {option.name}
                            </a>
                          )}
                        </Menu.Item>
                      ))}
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>

              {/* Vista */}
              <button
                type="button"
                className="p-2 -m-2 ml-5 sm:ml-7 text-gray-400 hover:text-gray-500"
                onClick={updateVista}
              >
                <span className="sr-only">Cambiar vista</span>
                {vista === 'grid' ? (
                  <ViewGridIcon className="w-5 h-5" aria-hidden="true" />
                ) : (
                  <ViewListIcon className="w-5 h-5" aria-hidden="true" />
                )}
              </button>

              {/* Filtro */}
              <button
                type="button"
                className="p-2 -m-2 ml-4 sm:ml-6 text-gray-400 hover:text-gray-500 lg:hidden"
                onClick={() => setMobileFiltersOpen(true)}
              >
                <span className="sr-only">Filters</span>
                <FilterIcon className="w-5 h-5" aria-hidden="true" />
              </button>
            </div>
          </div>

          <section aria-labelledby="products-heading" className="pt-6 pb-24">
            <h2 id="products-heading" className="sr-only">
              Productos
            </h2>

            <div className="grid grid-cols-1 lg:grid-cols-4 gap-x-8 gap-y-10">
              {/* Filters */}
              <form className="hidden lg:block">
                {filters.map((section) => (
                  <Disclosure
                    as="div"
                    key={section.id}
                    className="border-b border-gray-200 py-6"
                  >
                    {({ open }) => (
                      <>
                        <h3 className="-my-3 flow-root">
                          <Disclosure.Button className="py-3 bg-white w-full flex items-center justify-between text-sm text-gray-400 hover:text-gray-500">
                            <span className="text-base text-gray-700 font-sans font-semibold hover:text-gray-900 cursor-pointer transition duration-500 ease-in-out">
                              {section.name}
                            </span>
                            <span className="ml-6 flex items-center">
                              {open ? (
                                <MinusSmIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              ) : (
                                <PlusSmIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              )}
                            </span>
                          </Disclosure.Button>
                        </h3>
                        <Disclosure.Panel className="pt-6">
                          <div className="space-y-4">
                            {section.options.map((option, optionIdx) => (
                              <div
                                key={option.value}
                                className="flex items-center"
                              >
                                <input
                                  id={`filter-${section.id}-${optionIdx}`}
                                  name={`${section.id}[]`}
                                  defaultValue={option.value}
                                  type="checkbox"
                                  defaultChecked={option.checked}
                                  className="h-4 w-4 border-gray-300 rounded text-indigo-600 focus:ring-indigo-500 font-sans"
                                  onChange={() => {
                                    updateFilters(
                                      option.value,
                                      section.id,
                                      option.categoria_id,
                                      section,
                                    );
                                  }}
                                />
                                <label
                                  htmlFor={`filter-${section.id}-${optionIdx}`}
                                  className="ml-3 text-sm text-gray-600 font-sans hover:text-gray-900 hover:font-semibold cursor-pointer transition duration-500 ease-in-out"
                                >
                                  {option.label}
                                </label>
                              </div>
                            ))}
                          </div>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                ))}
              </form>

              {/* Product grid */}
              <div className="lg:col-span-3">
                <ListadoProductos
                  selectedFilters={selectedFilters}
                  sort={sort}
                  vista={vista}
                />
              </div>
            </div>
          </section>
        </main>
      </div>
    </div>
  );
}
