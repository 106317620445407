import React from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import { useMediaQuery } from 'react-responsive';

export default function Banner() {
  const slideData = [
    {
      srcDesktop:
        'https://dps2wxsijib8x.cloudfront.net/atst/BannerPrimero.png',
      srcTablet:
        'https://dps2wxsijib8x.cloudfront.net/atst/tablet/BannerPrimeroTablet.png',
      srcMobile:
        'https://dps2wxsijib8x.cloudfront.net/atst/movil/BannerPrimeroMovil.png',
      alt: '',
      btnText: 'PRODUCTOS',
      title: '¡Comunicación a Prueba de Todo! Portátiles Resistentes y Fiables',
      title_short: '¡Comunicación a Prueba de Todo!',
      description: 'Soluciones de comunicación empresarial a tu medida. Nuestros equipos ofrecen conectividad robusta y segura, perfecta para mantener a todos en tu empresa en la misma sintonía, ya sea en oficinas o en campo.',
      description_short: 'Soluciones de comunicación a medida para empresas, con conectividad segura en oficina y campo.'
    },
    {
      srcDesktop:
        'https://dps2wxsijib8x.cloudfront.net/atst/BannerSegundo.png',
      srcTablet:
        'https://dps2wxsijib8x.cloudfront.net/atst/tablet/BannerSegundoTablet.png',
      srcMobile:
        'https://dps2wxsijib8x.cloudfront.net/atst/movil/BannerSegundoMovil.png',
      alt: '',
      btnText: 'PRODUCTOS',
      title: 'Alcanza Cada Rincón: Portátiles de Comunicación para Todo Terreno',
      title_short: 'Alcanza Cada Rincón',
      description: 'Para empresas que valoran el tiempo y la eficiencia: nuestros radios portátiles son la herramienta perfecta para una comunicación rápida y fiable. Asegura la productividad manteniendo a tu equipo siempre conectado.',
      description_short: 'Radios portátiles para comunicación rápida y eficiente, manteniendo equipos conectados y productivos.'
    },
  ];

  const isDesktop = useMediaQuery({ minWidth: 1224 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1224 });
  const isMobile = useMediaQuery({ maxWidth: 768 });

  return (
    <Splide
      options={{
        type: 'loop',
        autoplay: true,
        interval: 3000,
        width: '100%',
      }}
    >
      {slideData.map((slide) => {
        let srcImage;

        if (isDesktop) {
          srcImage = slide.srcDesktop;
        } else if (isTablet) {
          srcImage = slide.srcTablet;
        } else if (isMobile) {
          srcImage = slide.srcMobile;
        } else {
          srcImage = slide.srcDesktop;
        }
        return (
          <SplideSlide key={slide.src}>
            <div className="flex flex-col items-center justify-center w-full relative border-gray-600">
              <img
                src={srcImage}
                alt={slide.alt}
                className="w-full inset-0 object-contain object-center h-full"
              />
              <div className="absolute z-10 flex h-full flex-col md:items-start justify-center w-full md:pb-10 pl-14 md:pl-20">
                <div className='grid grid-cols-5'>
                  <h1 className="col-span-5 md:col-span-3 font-bold text-white relative font-sans md:text-start uppercase leading-snug text-sm md:text-xl lg:text-4xl mb-2 md:mb-4">
                    <span className='hidden sm:block'>
                    {slide.title}
                    </span>
                    <span className='block sm:hidden'>
                    {slide.title_short}
                    </span>
                  </h1>
                  <div className='col-span-0 md:col-span-2'> </div>
                </div>
                <div className='grid grid-cols-5'>
                  <p className="col-span-5 md:col-span-3 text-white font-sans text-xs md:text-base lg:text-xl md:text-start pr-24 mb-4">
                    <span className='hidden sm:block'>
                      {slide.description}
                    </span>
                    <span className='block sm:hidden'>
                      {slide.description_short}
                    </span>
                  </p>
                  <div className='col-span-0 md:col-span-2'> </div>
                </div>
                <div>
                  <button
                    onClick={() => {
                      if (slide.btnText === 'ARRIENDO DE RADIOS') {
                        window.location.href = '/servicios/arriendo-de-radios';
                      } else if (slide.btnText === 'SERVICIO TÉCNICO') {
                        window.location.href = '/servicios/servicio-tecnico';
                      } else {
                        window.location.href = '/productos';
                      }
                    }}
                    className=" text-blue-800 text-center bg-white rounded md:rounded-full px-4 md:px-8 py-1 md:py-2 font-bold font-sans hover:scale-105 duration-200 text-xs md:text-base lg:text-xl"
                    type="button"
                  >
                    {slide.btnText}
                  </button>
                </div>
              </div>
            </div>
          </SplideSlide>
        );
      })}
    </Splide>
  );
}
