'use client';

import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FaWalkieTalkie } from 'react-icons/fa6';
import '@splidejs/splide/dist/css/splide.min.css';
import SplideCore from '@splidejs/splide';
import * as AutoScroll from '@splidejs/splide-extension-auto-scroll';
import confetiAnimation from '../../assets/confetti.json';
import LottieAnimation from '../../hooks/getLottie';
import { cargarProductosPorMarca } from '../../utils/loaders';

export default function CarruselProductos({ tipo, titulo }) {
  const splideRef = useRef();
  const splideInstance = useRef();
  const [productos, setProductos] = useState([]);

  useEffect(() => {
    const getProductos = async () => {
      const res = await cargarProductosPorMarca(tipo);
      setProductos(res.data);
    };
    getProductos();
  }, [tipo]);

  // Updates Splide when the component mounts and when 'productos' changes
  useEffect(() => {
    if (splideRef.current && productos.length > 0) {
      if (splideInstance.current) {
        splideInstance.current.destroy();
      }

      splideInstance.current = new SplideCore(splideRef.current, {
        type: 'loop',
        focus: 'center',
        perPage: 5,
        gap: '3rem',
        autoScroll: {
          speed: 0.8,
        },
        breakpoints: {
          480: {
            perPage: 2,
            gap: '1rem',
          },
          640: {
            perPage: 2,
            gap: '0.5rem',
          },
          1024: {
            perPage: 3,
            gap: '0.75rem',
          },
        },
      });

      splideInstance.current.mount(AutoScroll);
    }

    return () => {
      if (splideInstance.current) {
        splideInstance.current.destroy();
      }
    };
  }, [productos]);

  return (
    <div className="py-4 md:py-8 bg-gray-50 flex-col items-center justify-center content-center">
      <div className="px-4 md:px-8 relative z-10 flex flex-col md:flex-row justify-start items-start md:items-center md:content-center">
        <Link to="/productos" className="w-auto hover:translate-x-1 duration-300 flex items-center group border-blue-900 pl-2 hover:border-l-4 hover:animate-pulse">  
            <h2 className="z-10 flex items-center text-base sm:text-xl md:text-2xl font-extrabold font-sans text-blue-900 group-hover:text-blue-500 text-start">
              {titulo}
              <span className="hidden group-hover:inline text-xl text-blue-500 ml-4">
                <FaWalkieTalkie />
              </span>
            </h2>
        </Link>
      </div>
      <div className="splide relative z-10" ref={splideRef}>
        <div className="splide__track py-4 md:py-10">
          <ul className="splide__list">
            {productos.map((producto) => (
              <Link
                to={`/producto/${producto.titulo}`}
                state={{ id: producto.id }}
                className="splide__slide pb-6 w-14 h-[200px] md:h-[300px]"
                key={producto.id}
              >
                <div className="group flex flex-col items-center justify-center cursor-pointer h-full rounded-lg overflow-hidden shadow hover:shadow-lg border-4 border-white hover:border-blue-900 hover:scale-105 duration-300">
                  <div className="h-full w-full overflow-hidden rounded-t relative bg-white">
                    <img
                      src={producto.imagen_url}
                      alt={producto.titulo}
                      width={240}
                      height={240}
                      className="object-contain h-full w-full"
                    />
                    <div className="bg-green-950 opacity-0 group-hover:opacity-70 transition duration-300 absolute top-0 left-0 right-0 bottom-0 z-0" />
                    <div className="z-10 opacity-0 group-hover:opacity-100 absolute bottom-0 p-2">
                      <h4 className="text-white font-bold">
                        {producto.titulo}
                      </h4>
                      {/* eslint-disable react/no-danger */}
                      <div className="h-20 overflow-scroll overflow-x-hidden scroll-container">
                        <p
                          className="text-shadow text-white text-xs"
                          dangerouslySetInnerHTML={{
                            __html: producto.descripcion,
                          }}
                        />
                      </div>
                      {/* eslint-enable react/no-danger */}
                    </div>
                    {producto.estado === 'Proximamente' && (
                      <>
                        <div className="bg-green-100 absolute top-2 right-0 border-green-600 border-l border-t border-b rounded-l px-3">
                          <h3 className="uppercase text-green-600 font-semibold z-10 text-xs md:text-sm">
                            {producto.estado}
                          </h3>
                        </div>
                        <div className="absolute inset-0 w-full h-full z-0">
                          <LottieAnimation animationData={confetiAnimation} />
                        </div>
                      </>
                    )}
                  </div>
                  <div className="h-1/6 py-2 w-full relative flex items-center content-center justify-center fondo-formulario rounded-b">
                    <h3 className="text-center font-bold font-sans text-white text-xs md:text-base">
                      {producto.titulo}
                    </h3>
                  </div>
                </div>
              </Link>
            ))}
          </ul>
        </div>
      </div>
      {/* Boton para moviles */}
      <div className="md:hidden w-full relative z-10 flex justify-center mt-4">
        <Link
          to="/productos"
          className="w-auto text-blue-800 text-center bg-blue-900 rounded py-1 px-2 md:py-2 md:px-4 font-bold font-sans boton-banner relative flex shadow-md"
        >
          <span className="relative z-10 text-white text-xs md:text-base">
            TODOS LOS PRODUCTOS
          </span>
        </Link>
      </div>
    </div>
  );
}

CarruselProductos.propTypes = {
  tipo: PropTypes.string.isRequired,
  titulo: PropTypes.string.isRequired,
};
