import React, { useState, useEffect, useCallback } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import PropTypes from 'prop-types';
import Producto from './Producto';
import ProductoInformativo from './ProductoInformativo';
import LoaderProductos from './LoaderProductos';
import ScrollToTop from '../ScrollTop';

import {
  cargarProductosSubcategoria,
  cargarTodosLosProductos,
  cargarProductosPopulares,
  cargarProductosRecientes,
} from '../../utils/loaders';

export default function Listado({ selectedFilters, sort, vista }) {
  const [inicio, setInicio] = useState(0);
  const [fin, setFin] = useState(19);
  const [productos, setProductos] = useState([]);
  const [hasMoreItems, setHasMoreItems] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const cantidadProductosLoad = 12;

  const normalizeData = (data) => {
    const normalizedData = data.map((item) => ({
      id: item.id || item.producto_id,
      fecha_de_adicion: item.fecha_de_adicion || item.producto_fecha_de_adicion,
      visitas: item.visitas || item.producto_visitas,
      puntuacion: item.puntuacion || item.producto_puntuacion,
      numero_de_revisiones:
        item.numero_de_revisiones || item.producto_numero_de_revisiones,
      titulo: item.titulo || item.producto_titulo,
      estado: item.estado || item.producto_estado,
      descripcion: item.descripcion || item.producto_descripcion,
      imagenUrl: item.imagen_url || item.producto_imagen_url,
      subcategoria_id: item.subcategoria_id || item.categoria_id,
      pdfUrl: item.pdfUrl || item.producto_pdf_url,
    }));
    return normalizedData;
  };

  const cargarMasProductos = useCallback(async () => {
    setIsLoading(true);
    let data;
    let errorAPI;
    const subcategoriaIds = selectedFilters.flatMap((filter) =>
      filter.values.map((value) => value.categoria_id),
    );
    if (subcategoriaIds.length > 0) {
      ({ data, errorAPI } = await cargarProductosSubcategoria(
        inicio,
        fin,
        subcategoriaIds,
      ));
    } else if (sort === 'Mas populares') {
      ({ data, errorAPI } = await cargarProductosPopulares(inicio, fin));
    } else if (sort === 'Más reciente') {
      ({ data, errorAPI } = await cargarProductosRecientes(inicio, fin));
    } else {
      ({ data, errorAPI } = await cargarTodosLosProductos(
        inicio,
        fin,
        'visitas',
      ));
    }
    if (errorAPI) {
      setHasMoreItems(false);
      setIsLoading(false);
      setError(error);
      return;
    }

    if (data && data.length > 0) {
      const normalizedData = normalizeData(data);
      setProductos((prevProductos) => [...prevProductos, ...normalizedData]);
      setInicio((prevFin) => prevFin + normalizedData.length);
      setFin((prevFin) => prevFin + normalizedData.length);
    } else {
      setHasMoreItems(false);
    }
    setIsLoading(false);
  }, [inicio, fin, selectedFilters, sort, error]);

  const [shouldReload, setShouldReload] = useState(false); // Nuevo estado para controlar la recarga de productos

  // Función para reiniciar estados y cargar productos
  const reiniciarEstadosYCargarProductos = async () => {
    setProductos([]);
    setInicio(0);
    setFin(19);
    setHasMoreItems(true);
    setShouldReload(true); // Establecer shouldReload a true para iniciar una nueva carga
  };

  useEffect(() => {
    if (shouldReload) {
      cargarMasProductos();
      setShouldReload(false); // Resetear shouldReload una vez que la carga se ha completado
    }
  }, [shouldReload, cargarMasProductos]);

  useEffect(() => {
    reiniciarEstadosYCargarProductos(); // Llama a esta función cuando selectedFilters o sort cambien
  }, [selectedFilters, sort]);

  const renderContenido = () => {
    if (error) {
      return (
        <div
          className="flex items-center p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400"
          role="alert"
        >
          <svg
            className="flex-shrink-0 inline w-4 h-4 mr-3"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
          </svg>
          <span className="sr-only">Info</span>
          <div>
            <span className="font-medium">ERROR</span>{' '}
            {error || 'No se han encontrado productos'}
          </div>
        </div>
      );
    }

    if (isLoading && !productos.length) {
      return (
        <div
          className={`grid gap-x-4 gap-y-10 sm:gap-x-6 md:gap-y-0 lg:gap-x-8 ${
            vista === 'list'
              ? 'grid-cols-1 space-y-3'
              : 'grid-cols-2 md:grid-cols-4'
          }`}
        >
          {Array.from({ length: cantidadProductosLoad }, (_, index) => (
            <LoaderProductos key={index} vista={vista} />
          ))}
        </div>
      );
    }

    return (
      <InfiniteScroll
        dataLength={productos.length}
        next={cargarMasProductos}
        hasMore={hasMoreItems}
      >
        {vista === 'list' ? (
          <div className="grid grid-cols-1 gap-x-4 gap-y-10 sm:gap-x-6 md:grid-cols-1 md:gap-y-0 lg:gap-x-8 space-y-4">
            {productos.map((producto) => (
              <ProductoInformativo
                key={producto.id}
                id={producto.id}
                titulo={producto.titulo}
                estado={producto.estado}
                descripcion={producto.descripcion}
                imagenUrl={producto.imagenUrl}
                subcategoria={producto.subcategoria?.categoria?.nombre}
                pdfUrl={producto.pdfUrl}
              />
            ))}
          </div>
        ) : (
          <div className="grid grid-cols-2 gap-x-4 gap-y-10 sm:gap-x-6 md:grid-cols-4 md:gap-y-0 lg:gap-x-8">
            {productos.map((producto) => (
              <Producto
                key={producto.id}
                id={producto.id}
                titulo={producto.titulo}
                estado={producto.estado}
                descripcion={producto.descripcion}
                imagenUrl={producto.imagenUrl}
                subcategoria_id={producto.subcategoria_id}
                pdfUrl={producto.pdfUrl}
              />
            ))}
          </div>
        )}
      </InfiniteScroll>
    );
  };

  return (
    <div className="bg-white">
      <ScrollToTop />
      <div className="max-w-2xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
        {renderContenido()}
      </div>
    </div>
  );
}

Listado.propTypes = {
  sort: PropTypes.string.isRequired,
  vista: PropTypes.string.isRequired,
};
