import React from 'react';

export default function Nosotros() {
  return (
    <main className="w-full p-0 m-0 flex flex-col items-center">
      <div className="w-full group h-32 md:h-56 p-6 flex justify-center items-end bg-blue-50 relative overflow-hidden">
        <h1 className="z-10 text-xl sm:text-2xl md:text-4xl lg:text-5xl font-extrabold text-white font-sans group-hover:-translate-y-3 transition duration-500 ease-in-out">
          QUIÉNES SOMOS
        </h1>
        <img
          src="https://dps2wxsijib8x.cloudfront.net/atst/BannerPagina.webp"
          alt="Logo Andes Tec"
          className="object-cover w-full h-full absolute top-0 left-0 right-0 bottom-0 z-0"
        />
        <div className="absolute top-0 left-0 right-0 bottom-0 z-0 bg-blue-950 opacity-70" />
      </div>
      <div className="flex flex-col container p-8">
        <h2 className="uppercase color-azul-3 font-extrabold border-l-4 pl-4 border-blue-950 text-xl">
          Expertos en soluciones avanzadas y equipos de radiocomunicación
        </h2>
        <div className="pt-4 text-base text-gray-600">
          <p>
          ATST se especializa en equipos de radiocomunicación y soluciones para optimizar la comunicación en entornos laborales. Con más de <strong>10 años de experiencia en el mercado</strong>, hemos desarrollado una amplia expertise en este campo, respaldados por nuestra colaboración con la red de Dealers de Motorola, líderes mundiales en comunicación profesional.
          </p>
          <br />
          <p>
          Nos enorgullecemos en ATST de ofrecer <strong>soluciones de alta calidad</strong> a nuestros clientes, utilizando <strong>tecnología de vanguardia</strong> para cumplir con sus necesidades específicas. En ATST, nuestro compromiso es <strong>asesorar, comercializar e instalar equipos de radiocomunicación</strong> en bandas de VHF, UHF y onda corta, asegurando el mejor soporte para una experiencia excepcional de nuestros clientes.
          </p>
          <br />
          <p>
          Nuestro enfoque en <strong>soluciones personalizadas</strong> y nuestra <strong>experiencia en el sector de radiocomunicación</strong> nos posicionan para ayudar a empresas a lo largo de Chile a mejorar sus comunicaciones laborales. Capaces de manejar desde proyectos complejos hasta soluciones más sencillas, en ATST estamos preparados para enfrentar cualquier desafío, otorgando a nuestros clientes una <strong>ventaja competitiva en su sector</strong>.
          </p>
          <br />
        </div>
        <div className="w-full grid grid-cols-1 md:grid-cols-3 gap-2 justify-around items-start content-center">
          <div className=" h-auto pt-2 rounded-2xl flex items-center justify-center content-center hover:shadow-md transition-transform duration-300">
            <img
              width={400}
              height={300}
              src="https://dps2wxsijib8x.cloudfront.net/atst/MISION.png"
              alt="Mision de la empresa"
              className="w-full h-auto object-contain max-w-xl rounded-2xl border-4 border-white"
            />
          </div>
          <div className=" h-auto pt-2 rounded-2xl flex items-center justify-center content-center hover:shadow-md transition-transform duration-300">
            <img
              width={400}
              height={300}
              src="https://dps2wxsijib8x.cloudfront.net/atst/VISION.png"
              alt="Vision de la empresa"
              className="w-full h-auto object-contain max-w-xl rounded-2xl border-4 border-white"
            />
          </div>
          <div className=" h-auto pt-2 rounded-2xl flex items-center justify-center content-center hover:shadow-md transition-transform duration-300">
            <img
              width={400}
              height={300}
              src="https://dps2wxsijib8x.cloudfront.net/atst/VALORES.png"
              alt="Valores de la empresa"
              className="w-full h-auto object-contain max-w-xl rounded-2xl border-4 border-white"
            />
          </div>
        </div>
      </div>
    </main>
  );
}
