'use client';

import React, { useRef, useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';

export default function Nav() {
  const [showSublinks, setShowSublinks] = useState({
    Productos: false,
    Soluciones: false,
  });
  const dropdownRef = useRef(null);

  // Función para cerrar los subenlaces
  const closeDropdown = useCallback(() => {
    setShowSublinks({
      Productos: false,
      Soluciones: false,
    });
  }, [setShowSublinks]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        closeDropdown();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [closeDropdown, dropdownRef]);

  // Array de links
  const links = [
    { name: 'Inicio', path: '/' },
    { name: 'Productos', path: '/productos' },
    {
      name: 'Nosotros',
      sublinks: [
        { name: 'Quienes somos', path: '/nosotros' },
        { name: 'Contacto', path: '/contacto' },
      ],
    },
  ];

  const toggleSublinks = (event, link) => {
    // Si el menú clickeado no tiene sublinks, entonces no hacemos nada
    if (!links.find((item) => item.name === link).sublinks) {
      return;
    }
    event.preventDefault();

    // Primero, reseteamos todos los estados a false
    const resetState = Object.keys(showSublinks).reduce((acc, key) => {
      acc[key] = false;
      return acc;
    }, {});

    // Luego, cambiamos el estado del menú clickeado
    setShowSublinks({
      ...resetState,
      [link]: !showSublinks[link],
    });
  };

  return (
    <nav className="w-full" ref={dropdownRef}>
      <ul className="flex justify-center gap-x-4 items-center content-center">
        {links.map((link) => (
          <li
            key={link.path}
            className=" text-blue-900 hover:text-blue-950 relative"
            style={{ overflow: 'visible' }}
          >
            <Link to={link.path}>
              <span
                onClick={(e) => toggleSublinks(e, link.name)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    toggleSublinks(e, link.name);
                  }
                }}
                role="button"
                tabIndex="0"
                className="font-bold text-green-600 hover:text-blue-800 text-base font-sans uppercase hover:bg-gray-50 px-4 duration-300 border-white hover:shadow rounded py-2"
              >
                {link.name}
                {link.sublinks && (
                  <svg
                    className={`inline-block ml-1 w-6 transition-transform duration-300 ease-in-out transform ${
                      showSublinks[link.name] ? 'rotate-180' : ''
                    }`}
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M6.293 7.293a1 1 0 011.414 0L10 9.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                )}
              </span>
            </Link>
            {link.sublinks && link.sublinks.length > 0 ? (
              <ul
                className={`${
                  showSublinks[link.name]
                    ? 'pointer-events-auto opacity-100 scale-100'
                    : 'pointer-events-none opacity-0 hidden scale-95'
                } transform transition-all duration-300 ease-in-out absolute overflow-hidden border shadow px-2 py-2 rounded-b bg-white z-20 mt-2 w-48`}
              >
                {link.sublinks.map((sublink, j) => (
                  <li
                    key={sublink.path}
                    className={`space-y-4 flex ${
                      j < link.sublinks.length - 1 ? 'border-b mb-1 pb-1' : ''
                    }`}
                    style={{
                      overflow: 'visible',
                      pointerEvents:
                        link.sublinks && link.sublinks.length ? 'auto' : 'none',
                    }}
                  >
                    <button
                      onClick={closeDropdown}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          closeDropdown();
                        }
                      }}
                      className="w-full text-left flex"
                      type="button"
                    >
                      <Link
                        to={sublink.path}
                        className="hover:bg-blue-950 px-6 duration-300 border-white hover:text-white font-semibold rounded py-2 w-full min-w-max font-sans"
                      >
                        {sublink.name}
                      </Link>
                    </button>
                  </li>
                ))}
              </ul>
            ) : null}
          </li>
        ))}
      </ul>
    </nav>
  );
}
