import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faNavicon } from '@fortawesome/free-solid-svg-icons';
import NavMovil from './NavMovil';

import CarritoMovil from './CarritoMovil';

function AsideMovil() {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);
  const onButtonClick = () => setIsOpen(!isOpen);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);

  const handleClickOutside = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      setIsOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="flex justify-center items-center content-center overflow-hidden">
      <button
        aria-label="Abrir menú"
        className="flex items-center content-center duration-200"
        onClick={onButtonClick}
        type="button"
      >
        <FontAwesomeIcon icon={faNavicon} className="text-blue-950 text-2xl" />
      </button>
      {isOpen && (
        <div>
          <div className="absolute bg-black bg-opacity-50 inset-0 z-40" />
          <div
            ref={ref}
            className="origin-top absolute right-0 w-64 top-0 h-screen overflow-hidden shadow-xl bg-white ring-1 ring-black ring-opacity-5 transition transform ease-in-out duration-200 z-50 border"
          >
            <div className="p-2 relative overflow-hidden h-full">
              <NavMovil />
              <CarritoMovil />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AsideMovil;
