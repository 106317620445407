import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';

function ProductoInformativo({
  titulo,
  estado,
  descripcion,
  subcategoria,
  imagenUrl,
}) {
  const sanitizedHTML = DOMPurify.sanitize(descripcion);
  return (
    <Link
      to={`/producto/${titulo}`}
      className="grid grid-cols-5 h-40 border rounded shadow hover:shadow-md hover:border-blue-900 duration-200 overflow-hidden"
    >
      <div className="col-span-1 w-full h-40 p-2">
        <img
          src={imagenUrl}
          className="w-full h-full object-contain"
          alt={titulo}
        />
      </div>
      <div className="col-span-4 p-4">
        <div className="flex justify-between items-center content-center mb-2">
          <h2 className="text-gray-700 font-bold text-lg flex items-center content-center">
            {titulo}{' '}
            {estado === 'Disponible' && (
              <span className="hidden md:inline-flex bg-red-500 text-white text-xs uppercase px-2 py-0.5 rounded font-semibold ml-3">
                {estado}
              </span>
            )}
          </h2>
          <p className="text-gray-500 text-sm">{subcategoria}</p>
        </div>
        <hr className="border border-gray-300 my-1" />
        <div className="h-20 overflow-y-auto overflow-x-hidden scroll-container">
          {/* eslint-disable */}
          <p
            className="text-shadow text-gray-600 text-base"
            dangerouslySetInnerHTML={{ __html: sanitizedHTML }}
          />
          {/* eslint-enable */}
        </div>
      </div>
    </Link>
  );
}

ProductoInformativo.propTypes = {
  titulo: PropTypes.string.isRequired,
  estado: PropTypes.string.isRequired,
  descripcion: PropTypes.string.isRequired,
  subcategoria: PropTypes.string.isRequired,
  imagenUrl: PropTypes.string.isRequired,
};

export default ProductoInformativo;
