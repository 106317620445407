import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { decreaseQuantity } from '../actions/cartActions';

function CarritoMovil() {
  const dispatch = useDispatch();
  const productosCarrito = useSelector((state) => state.cart);
  return (
    <>
      {productosCarrito.cart.length > 0 ? (
        <Link
          className="p-2 flex justify-center items-center content-center hover:bg-blue-50 duration-200"
          to="/cotizaciones"
        >
          <h2 className="font-bold text-base font-sans uppercase text-blue-900 bg-blue-100 w-full text-center py-2 rounded shadow">
            VER COTIZACIONES ({productosCarrito.cart.length})
          </h2>
        </Link>
      ) : null}
      <div className="p-2 relative h-96 space-y-2 max-h-40 overflow-y-auto">
        {productosCarrito.cart.length > 0 ? (
          <>
            {productosCarrito.cart.map((producto) => (
              <div
                key={producto.product.producto_id}
                className="flex justify-between border rounded p-2 w-full hover:border-blue-800 hover:bg-gray-100 duration-200"
              >
                <Link
                  to={`/producto/${producto.product.producto_titulo}`}
                  state={{ id: producto.product.producto_id }}
                  className="flex items-center content-center w-full"
                >
                  <div>
                    <img
                      src={producto.product.producto_imagen_url}
                      alt={producto.product.producto_titulo}
                      className="w-9 h-12 object-cover"
                    />
                  </div>
                  <div className="flex flex-col justify-center items-start content-center ml-2">
                    <h2 className="font-bold text-gray-800">
                      {producto.product.producto_titulo}
                    </h2>
                    <p className="text-xs font-semibold text-gray-500">
                      Cantidad {producto.quantity}
                    </p>
                  </div>
                </Link>
                <button
                  aria-label="Eliminar producto"
                  onClick={(event) => {
                    event.stopPropagation();
                    dispatch(decreaseQuantity(producto.product.producto_id));
                  }}
                  className="text-red-600 hover:text-red-800 hover:scale-105 duration-200 w-10 z-20"
                  type="button"
                >
                  <FontAwesomeIcon icon={faCircleXmark} className="text-xl" />
                </button>
              </div>
            ))}
          </>
        ) : (
          <>
            <hr className="border-t-2 border-gray-200" />
            <p className="block text-center py-2 px-1 text-xs md:text-sm text-gray-600 font-semibold font-sans relative z-10">
              No hay productos en el carrito
            </p>
          </>
        )}
      </div>
    </>
  );
}

export default CarritoMovil;
