/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable spaced-comment */
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import HeaderInformativo from './components/HeaderInformativo';
import Footer from './components/Footer';
import Contacto from './pages/Contacto';
import Productos from './pages/Productos';
import Producto from './components/productos/InformacionProducto';
import Inicio from './pages/Inicio';
import Nosotros from './pages/Nosotros';
import Cotizaciones from './pages/Cotizaciones';
import WhatsAppButton from './components/WhatsAppButton';

function App() {
  return (
    <>
{/* Google Tag Manager */}
      <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=G-TDDHKX23WL"
      />
      <script
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-TDDHKX23WL');
          `,
        }}
      />
      {/* End Google Tag Manager */}
      <Router>
      <HeaderInformativo />
      <Header />
      <WhatsAppButton />
      <Routes>
        <Route path="/contacto" element={<Contacto />} />
        <Route path="/productos" element={<Productos />} />
        <Route path="/producto/:titulo" element={<Producto />} />
        <Route path="/nosotros" element={<Nosotros />} />
        <Route path="/cotizaciones" element={<Cotizaciones />} />
        <Route path="/" element={<Inicio />} />
      </Routes>
      <Footer />
    </Router>
    {/* 
      <div>
        MANTENIMIENTO 
        <div className="flex justify-center items-center h-screen relative flex-col">
          <div className="z-20 text-center">
            <h1 className="text-4xl text-white uppercase font-bold">
              Estamos trabajando en el sitio web
            </h1>
          </div>
          
          <div className="col-span-2 hidden sm:flex items-center content-center text-white mt-4">
            <div className="mr-8 hidden sm:flex text-white relative z-20">
              <p className="font-semibold text-base md:text-xl">
                contacto@atst.cl
              </p>
            </div>
            <div className="hidden md:flex items-center content-center gap-x-8 text-white relative z-20">
              <a
                href="https://instagram.com/andes_tec?igshid=OGQ5ZDc2ODk2ZA=="
                target="_blank"
                rel="noreferrer"
                className="text-white w-5"
              >
                <img
                  src="/instagram.svg"
                  alt="Instagram"
                  className="text-white w-5"
                />
              </a>
              <a
                href="https://www.linkedin.com/company/andes-tec.cl/"
                target="_blank"
                rel="noreferrer"
                className="text-white w-5"
              >
                <img
                  src="/linkedin.svg"
                  alt="Linkedin"
                  className="text-white w-5"
                />
              </a>
              <a href="/contacto" className="text-white w-5">
                <img
                  src="/correo-electronico.svg"
                  alt="Correo"
                  className="text-white w-5"
                />
              </a>
              <a
                href="https://www.facebook.com/profile.php?id=100063911780015&mibextid=2JQ9oc"
                target="_blank"
                rel="noreferrer"
                className="text-white w-5"
              >
                <img
                  src="/facebook.svg"
                  alt="Correo"
                  className="text-white w-5"
                />
              </a>
            </div>
          </div>
          <div className="bg-blue-950 opacity-70 absolute top-0 left-0 right-0 bottom-0 z-10" />
          <img
            src="https://dps2wxsijib8x.cloudfront.net/atst/Footer.webp"
            alt="Logo Andes Tec"
            className="object-cover w-full h-full absolute top-0 left-0 right-0 bottom-0 z-0"
          />
        </div>
      </div>
    */}
    </>
  );
}

export default App;
