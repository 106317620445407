import React from 'react';

export default function LoaderProducto() {
  return (
    <div
      role="status"
      className="grid grid-cols-2 h-full w-full border rounded shadow overflow-hidden animate-pulse p-8"
    >
      <div className="col-span-2 w-full p-2 flex gap-x-4">
        <p className="text-shadow bg-gray-200 rounded text-base h-6 w-20" />
        <p className="text-shadow bg-gray-200 rounded text-base h-6 w-20 ml-1" />
        <p className="text-shadow bg-gray-200 rounded text-base h-6 w-20 ml-1" />
      </div>
      <div className="col-span-1 w-full h-[400px] bg-gray-200 p-2">
        <svg
          className="w-10 h-10 text-gray-200"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 20 18"
        >
          <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
        </svg>
      </div>
      <div className="col-span-1 p-4">
        <div className="flex flex-col justify-between items-start content-center mb-2">
          <p className="text-gray-700 font-bold text-lg flex items-center content-center bg-gray-200 rounded w-1/5 h-8" />
          <p className="text-gray-500 text-sm bg-gray-200 rounded w-1/12 h-3 mt-2" />
        </div>
        <hr className="border border-gray-200 my-1" />
        <div className="h-20 overflow-y-auto overflow-x-hidden scroll-container mt-2">
          <p className="text-shadow bg-gray-200 rounded text-base h-3 w-3/4" />
          <p className="text-shadow bg-gray-200 rounded text-base h-3 w-4/6 mt-1" />
          <p className="text-shadow bg-gray-200 rounded text-base h-3 w-5/6 mt-1" />
          <p className="text-shadow bg-gray-200 rounded text-base h-3 w-3/4 mt-1" />
          <p className="text-shadow bg-gray-200 rounded text-base h-3 w-4/6 mt-1" />
        </div>
        <div className="flex gap-x-2 mt-4">
          <p className="text-shadow bg-gray-200 rounded text-base h-10 w-24" />
          <p className="text-shadow bg-gray-200 rounded text-base h-10 w-24" />
          <p className="text-shadow bg-gray-200 rounded text-base h-10 w-24" />
        </div>
        <div className="flex gap-x-2 mt-4">
          <p className="text-shadow bg-gray-200 rounded text-base h-10 w-24" />
          <p className="text-shadow bg-gray-200 rounded text-base h-10 w-24" />
          <p className="text-shadow bg-gray-200 rounded text-base h-10 w-24" />
        </div>
        <div className="flex gap-x-2 mt-4">
          <p className="text-shadow bg-gray-200 rounded text-base h-10 w-24" />
          <p className="text-shadow bg-gray-200 rounded text-base h-10 w-[200px]" />
        </div>
      </div>
    </div>
  );
}
