import {
  ADD_TO_CART,
  REMOVE_FROM_CART,
  DECREASE_QUANTITY,
} from '../actions/cartActions';

const initialState = {
  cart: [],
};
// eslint-disable-next-line default-param-last
const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TO_CART: {
      const existingProductIndex = state.cart.findIndex(
        (item) =>
          item.product.producto_id === action.payload.product.producto_id,
      );

      if (existingProductIndex >= 0) {
        const updatedCart = [...state.cart];
        const updatedItem = { ...updatedCart[existingProductIndex] };

        // Suma la cantidad del producto existente con la cantidad del producto nuevo.
        updatedItem.quantity += action.payload.quantity;

        updatedCart[existingProductIndex] = updatedItem;

        return {
          ...state,
          cart: updatedCart,
        };
      }
      return {
        ...state,
        cart: [...state.cart, action.payload],
      };
    }
    case REMOVE_FROM_CART: {
      return {
        ...state,
        cart: state.cart.filter(
          (item) => item.product.id !== action.payload.productId,
        ),
      };
    }
    case DECREASE_QUANTITY: {
      // Primero disminuir la cantidad
      const decreasedCart = state.cart.map((item) => {
        if (item.product.producto_id === action.payload.productId) {
          return {
            ...item,
            quantity: item.quantity - 1,
          };
        }
        return item;
      });

      // Luego filtrar los elementos con cantidad 0
      const filteredCart = decreasedCart.filter((item) => item.quantity > 0);

      return {
        ...state,
        cart: filteredCart,
      };
    }
    default:
      return state;
  }
};

export default cartReducer;
