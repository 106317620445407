import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import LottieAnimation from '../hooks/getLottie';
import { decreaseQuantity } from '../actions/cartActions';
import emptyAnimation from '../assets/empty.json';

import FormularioCotizacion from '../components/formularios/FormularioCotizacion';

function Cotizaciones() {
  const dispatch = useDispatch();
  const productosCarrito = useSelector((state) => state.cart);
  return (
    <main className="w-full p-0 m-0 flex flex-col items-center">
      <div className="w-full group h-56 p-6 flex justify-center items-end bg-blue-50 relative overflow-hidden">
        <h1 className="z-10 text-5xl font-extrabold text-white font-sans group-hover:-translate-y-3 transition duration-500 ease-in-out">
          REVISA TUS COTIZACIONES
        </h1>
        <img
          src="https://dps2wxsijib8x.cloudfront.net/atst/BannerPagina.webp"
          alt="Logo Andes Tec"
          className="object-cover w-full h-full absolute top-0 left-0 right-0 bottom-0 z-0"
        />
        <div className="absolute top-0 left-0 right-0 bottom-0 z-0 bg-blue-950 opacity-70" />
      </div>
      <div className="container grid grid-cols-1 sm:grid-cols-2 gap-8 p-10">
        {/* Listado de productos cotizados */}
        <div className="w-full">
          <h2 className="font-bold text-2xl text-blue-900 mb-4">
            Productos cotizados
          </h2>
          <div className="relative overflow-x-auto shadow-md sm:rounded-lg max-h-96 h-96 overflow-hidden overflow-y-auto scroll-grande p-3">
            {productosCarrito.cart.length > 0 ? (
              <>
                {productosCarrito.cart.map((producto) => (
                  <div
                    key={producto.product.producto_id}
                    className="flex justify-between border rounded p-2 w-full hover:border-blue-800 hover:bg-gray-100 duration-200"
                  >
                    <Link
                      to={`/producto/${producto.product.producto_titulo}`}
                      state={{ id: producto.product.producto_id }}
                      className="flex items-center content-center w-full"
                    >
                      <div>
                        <img
                          src={producto.product.producto_imagen_url}
                          alt={producto.product.producto_titulo}
                          className="w-9 h-12 object-cover"
                        />
                      </div>
                      <div className="flex flex-col justify-center items-start content-center ml-2">
                        <h2 className="font-bold text-gray-800">
                          {producto.product.producto_titulo}
                        </h2>
                        <p className="text-xs font-semibold text-gray-500">
                          Cantidad {producto.quantity}
                        </p>
                      </div>
                    </Link>
                    <button
                      aria-label="Eliminar producto"
                      onClick={(event) => {
                        event.stopPropagation();
                        dispatch(
                          decreaseQuantity(producto.product.producto_id),
                        );
                      }}
                      className="text-red-600 hover:text-red-800 hover:scale-105 duration-200 w-10"
                      type="button"
                    >
                      <FontAwesomeIcon
                        icon={faCircleXmark}
                        className="text-xl"
                      />
                    </button>
                  </div>
                ))}
              </>
            ) : (
              <>
                <button
                  className="block px-2 text-xs py-1 font-semibold font-sans relative bg-blue-100 rounded hover:shadow duration-200 hover:scale-95 border border-blue-200 text-blue-800"
                  type="button"
                >
                  ¡Comunícate claro! Descubre nuestros equipos
                </button>
                <p className="block py-2 px-1 text-sm text-gray-600 font-semibold font-sans relative">
                  No hay productos en el carrito...
                </p>
                <div className="absolute inset-0 w-44 flex items-center justify-center mx-auto">
                  <LottieAnimation animationData={emptyAnimation} />
                </div>
              </>
            )}
          </div>
        </div>
        {/* Formulario de cotizacion */}
        <div
          className="relative border-4 border-gray-100 hover:border-gray-200 rounded-md hover:shadow-lg transition duration-500"
          style={{
            backgroundImage:
              'linear-gradient(to left top, #e2f7fb, #d4f1f8, #c7ebf6, #bae5f4, #addff3)',
          }}
        >
          <div className="rounded-t fondo-arriendo-radios p-4 relative z-10">
            <h3 className="font-extrabold text-start md:text-xl text-white uppercase">
              Realizar cotización
            </h3>
            <p className="text-start text-sm md:text-base text-gray-100 mt-3">
              Completa la información para realizar la cotización y poder
              realizar un servicio de calidad.
            </p>
          </div>
          <div className="px-4 pb-4 relative z-10">
            <FormularioCotizacion />
          </div>
          <img
            src="https://dps2wxsijib8x.cloudfront.net/atst/BannerContacto.png"
            alt="Logo Andes Tec"
            className="object-cover w-full h-full absolute top-0 left-0 right-0 bottom-0 z-0"
          />
          <div className="absolute top-0 left-0 right-0 bottom-0 z-0 bg-green-950 opacity-70" />
        </div>
      </div>
    </main>
  );
}

export default Cotizaciones;
