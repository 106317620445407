import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { buscarTituloCompleto } from '../utils/loaders';
import emptyAnimation from '../assets/empty.json';
import LottieAnimation from '../hooks/getLottie';

let timer;

export default function HeaderInformativo() {
  const [resultadosBusqueda, setResultadosBusqueda] = useState([]);
  const [busquedaRealizada, setBusquedaRealizada] = useState(false);
  const resultadosRef = useRef(null);
  const [cargando, setCargando] = useState(false);
  const marcadoresDePosicion = [{ id: 'a1' }, { id: 'b2' }];

  const handleInputChange = (e) => {
    const texto = e.target.value.trim();
    clearTimeout(timer);
    if (texto === '') {
      setResultadosBusqueda([]);
      setBusquedaRealizada(false);
      return;
    }

    timer = setTimeout(async () => {
      setCargando(true);
      const resultados = await buscarTituloCompleto(texto);
      setResultadosBusqueda(resultados.data || []);
      setBusquedaRealizada(true);
      setCargando(false);
    }, 300);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        resultadosRef.current &&
        !resultadosRef.current.contains(event.target)
      ) {
        setResultadosBusqueda([]);
        setBusquedaRealizada(false); // También cambiar esto aquí
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const stripHtmlTags = (input) => {
    return input ? input.replace(/<\/?[^>]+(>|$)/g, '') : '';
  };

  const renderResultados = () => {
    if (cargando) {
      return (
        <div className="absolute top-20 sm:w-3/6 left-5 sm:left-auto right-5 h-52 bg-white flex items-center justify-center z-30 overflow-hidden p-2 shadow border rounded">
          <ul className="w-full h-full animate-pulse" role="status">
            {marcadoresDePosicion.map((item) => (
              <li className="flex" key={item.id}>
                <div className="w-20 h-20 min-w-20 min-h-20 bg-gray-200 p-2">
                  <svg
                    className="w-10 h-10 text-gray-200"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 18"
                  >
                    <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
                  </svg>
                </div>
                <div className="w-full pl-2">
                  <div className="h-20 overflow-y-auto overflow-x-hidden scroll-container mt-2">
                    <p className="text-shadow bg-gray-200 rounded text-base h-2 w-3/4" />
                    <p className="text-shadow bg-gray-200 rounded text-base h-2 w-4/6 mt-1" />
                    <p className="text-shadow bg-gray-200 rounded text-base h-2 w-3/4 mt-1" />
                    <p className="text-shadow bg-gray-200 rounded text-base h-2 w-2/5 mt-1" />
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      );
    }

    if (resultadosBusqueda.length > 0) {
      return (
        <div className="absolute top-20 sm:w-3/6 left-5 sm:left-auto right-5 h-60 bg-white flex items-center justify-center z-30 overflow-hidden overflow-y-auto p-2 shadow border rounded">
          <ul className="w-full h-full">
            {resultadosBusqueda.map((resultado) => (
              <Link
                to={`/producto/${resultado.titulo}`}
                state={{ id: resultado.id }}
                key={resultado.id}
                className="flex items-center gap-2 p-2 hover:bg-gray-200 cursor-pointer duration-200 rounded-md"
              >
                <div className="w-20 h-20 min-w-20 min-h-20 flex items-center justify-center bg-gray-200 rounded-md">
                  <img
                    src={resultado.imagen_url}
                    alt={resultado.titulo}
                    className="w-20 h-20"
                  />
                </div>
                <div className="flex flex-col w-full">
                  <h3 className="font-semibold">{resultado.titulo}</h3>
                  <div className="text-sm text-gray-500">
                    {stripHtmlTags(resultado.descripcion).substring(0, 180) +
                      (resultado.descripcion.length > 50 ? '...' : '')}
                  </div>
                </div>
              </Link>
            ))}
          </ul>
        </div>
      );
    }

    if (busquedaRealizada) {
      return (
        <div className="absolute top-20 sm:w-3/6 left-5 sm:left-auto right-5 h-60 bg-white flex flex-col items-center justify-center z-30 overflow-hidden overflow-y-auto p-2 shadow border rounded">
          <div className="inset-0 w-40 flex items-center justify-center mx-auto">
            <LottieAnimation animationData={emptyAnimation} />
          </div>
          <p className="text-gray-500 text-center select-none">
            No se encontraron resultados
          </p>
        </div>
      );
    }

    return null;
  };

  return (
    <div className="w-full fondo-formulario grid grid-cols-4 items-center gap-4 py-4 px-4">
      <div className="col-span-2 hidden sm:flex items-center content-center text-white">
        <div className="mr-8 hidden sm:flex">
          <p className="font-semibold text-base md:text-xl">contacto@atst.cl</p>
        </div>
        <div className="hidden md:flex items-center content-center gap-x-8 text-white">
          <Link to="/contacto" className="text-white w-5">
            <img
              src="/correo-electronico.svg"
              alt="Correo"
              className="text-white w-5"
            />
          </Link>
        </div>
      </div>
      <div className="flex group items-center content-center col-span-4 sm:col-span-2 hover:border-blue-400 hover:shadow-2xl ease-in-out duration-200 text-white rounded-md border bg-blue-950/20 overflow-hidden relative">
        <FontAwesomeIcon
          icon={faSearch}
          className="fi fi-rr-search text-white group-hover:text-white mt-0.5 absolute left-3 z-10"
        />
        <input
          type="text"
          placeholder="Buscar productos"
          className="w-full bg-blue-950/20 py-2 pl-9 outline-none border-none focus:ring-0 text-white placeholder-white font-semibold"
          onChange={handleInputChange}
        />
      </div>
      <div ref={resultadosRef}>{renderResultados()}</div>
    </div>
  );
}
