'use client';

import React, { useEffect, useRef, useCallback } from 'react';
import lottie from 'lottie-web';

function LottieAnimation({ animationData }) {
  const container = useRef(null);
  const animation = useRef(null);

  const loadAnimationData = useCallback(() => {
    try {
      animation.current = lottie.loadAnimation({
        container: container.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData,
      });
    } catch (error) {
      // console.error('Error al cargar la animación:', error);
    }
  }, [animationData]);

  useEffect(() => {
    if (animation.current) {
      try {
        animation.current.destroy();
        loadAnimationData();
      } catch (error) {
        // console.error('Error al destruir la animación:', error);
      }
    } else {
      loadAnimationData();
    }

    return () => {
      try {
        animation.current.destroy();
      } catch (error) {
        // console.error('Error al destruir la animación:', error);
      }
    };
  }, [animationData, loadAnimationData]);

  return <div ref={container} />;
}

export default LottieAnimation;
