import React from 'react';
import { Link } from 'react-router-dom';
import Nav from './Nav';
import AsideMovil from './AsideMovil';
import Carrito from './Carrito';

function Header() {
  return (
    <nav className="bg-white border-gray-200 px-5">
      <div className="w-full flex flex-wrap items-center justify-between mx-auto p-2">
        {/* Logo */}
        <Link to="/" className="flex items-center h-16 md:h-24 xl:h-28">
          <img
            src="https://dps2wxsijib8x.cloudfront.net/atst/LOGO-ATST.png"
            className="w-full h-full object-contain"
            alt="ATST Logo"
          />
        </Link>
        <div className="flex items-center justify-between">
          {/* Menu de navegacion */}
          <div className="hidden xl:block">
            <Nav />
          </div>
          {/* Menu movil y carrito */}
          <div className="hidden xl:block ml-4">
            <Carrito />
          </div>
          {/* Menu movil */}
          <div className="flex xl:hidden overflow-hidden">
            <AsideMovil />
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Header;
