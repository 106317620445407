import React, { useState, useRef, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { decreaseQuantity } from '../actions/cartActions';
import LottieAnimation from '../hooks/getLottie';
import emptyAnimation from '../assets/empty.json';

function Carrito() {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);
  const onButtonClick = () => setIsOpen(!isOpen);
  const dispatch = useDispatch();
  const productosCarrito = useSelector((state) => state.cart);

  const handleClickOutside = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      setIsOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="hidden xl:flex relative col-span-1 justify-end items-center content-center">
      {productosCarrito.cart.length > 0 && (
        <div className="absolute -top-2 left-3 z-20 bg-red-500 rounded-full h-6 w-6 flex justify-center items-center content-center">
          <p className="text-white font-bold text-xs">
            {productosCarrito.cart.length}
          </p>
        </div>
      )}
      <button
        onClick={onButtonClick}
        className={`flex items-center content-center group hover:bg-gray-100 hover:shadow duration-200 py-1 px-3 rounded ${
          isOpen ? 'bg-gray-100 shadow' : ''
        }`}
        type="button"
      >
        <img
          src="https://dps2wxsijib8x.cloudfront.net/imagenes-generales/extras/cotizacion.webp"
          alt="logo"
          className={`w-12 group-hover:-rotate-6 duration-200 ${
            isOpen ? '-rotate-6 animate-none' : ''
          } animate-wiggle`}
        />
        <h2 className="font-bold text-base font-sans uppercase text-green-600 hover:text-blue-950">
          COTIZAR
        </h2>
      </button>
      {isOpen && (
        <div
          ref={ref}
          className="origin-top absolute right-0 mt-[470px] w-80 rounded-md shadow-xl bg-white ring-1 ring-black ring-opacity-5 transition transform ease-in-out duration-200 translate-y-2 z-50 border"
        >
          {productosCarrito.cart.length > 0 ? (
            <Link
              to="/cotizaciones"
              className="p-2 flex justify-center items-center content-center hover:bg-blue-50 duration-200"
            >
              <h2 className="font-bold text-base font-sans uppercase text-blue-900">
                VER COTIZACIONES ({productosCarrito.cart.length})
              </h2>
            </Link>
          ) : null}
          <div className="p-2 relative h-96 space-y-2 max-h-96 overflow-y-auto">
            {productosCarrito.cart.length > 0 ? (
              <>
                {productosCarrito.cart.map((producto) => (
                  <div
                    key={producto.product.producto_id}
                    className="flex justify-between border rounded p-2 w-full hover:border-blue-800 hover:bg-gray-100 duration-200"
                  >
                    <Link
                      to={`/producto/${producto.product.producto_titulo}`}
                      state={{ id: producto.product.producto_id }}
                      className="flex items-center content-center w-full"
                    >
                      <div>
                        <img
                          src={producto.product.producto_imagen_url}
                          alt={producto.product.producto_titulo}
                          className="w-9 h-12 object-cover"
                        />
                      </div>
                      <div className="flex flex-col justify-center items-start content-center ml-2">
                        <h2 className="font-bold text-gray-800">
                          {producto.product.producto_titulo}
                        </h2>
                        <p className="text-xs font-semibold text-gray-500">
                          Cantidad {producto.quantity}
                        </p>
                      </div>
                    </Link>
                    <button
                      aria-label="Eliminar producto"
                      onClick={(event) => {
                        event.stopPropagation();
                        dispatch(
                          decreaseQuantity(producto.product.producto_id),
                        );
                      }}
                      className="text-red-600 hover:text-red-800 hover:scale-105 duration-200 w-10 z-20"
                      type="button"
                    >
                      <FontAwesomeIcon
                        icon={faCircleXmark}
                        className="text-xl"
                      />
                    </button>
                  </div>
                ))}
              </>
            ) : (
              <>
                <button
                  className="block px-2 text-xs py-1 font-semibold font-sans relative z-10 bg-blue-100 rounded hover:shadow duration-200 hover:scale-95 border border-blue-200 text-blue-800"
                  type="button"
                >
                  ¡Comunícate claro! Descubre nuestros equipos
                </button>
                <p className="block py-2 px-1 text-sm text-gray-600 font-semibold font-sans relative z-10">
                  No hay productos en el carrito...
                </p>
                <div className="absolute inset-0 w-44 flex items-center justify-center mx-auto">
                  <LottieAnimation animationData={emptyAnimation} />
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default Carrito;
