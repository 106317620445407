import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import toast, { Toaster } from 'react-hot-toast';
import { addToCart } from '../../actions/cartActions';
import LoaderProducto from './LoaderProducto';
import {
  obtenerProductoTitulo,
  cargarCaracteristicaProducto,
  cargarAccesoriosProducto,
} from '../../utils/loaders';

function InformacionProducto() {
  const { titulo } = useParams();
  const dispatch = useDispatch();
  const [producto, setProducto] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { handleSubmit, control, setValue } = useForm(); // Declaramos todo en una sola línea
  const [productoCantidad, setProductoCantidad] = useState(1);
  const [caracteristicas, setCaracteristicas] = useState([]);
  const [accesorios, setAccesorios] = useState([]);
  const [id, setId] = useState(null);

  const increment = () => {
    const newCount = productoCantidad + 1;
    setProductoCantidad(newCount);
    setValue('productoCantidad', newCount); // actualiza el valor del formulario
  };

  const decrement = () => {
    const newCount = Math.max(1, productoCantidad - 1); // no permite menos de 1
    setProductoCantidad(newCount);
    setValue('productoCantidad', newCount); // actualiza el valor del formulario
  };

  const miToastPersonalizado = (productoData) =>
    toast.custom((t) => {
      const customData = productoData || {};
      return (
        <div
          className={`${
            t.visible ? 'animate-enter' : 'animate-leave'
          } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
        >
          {/* Utilizar customData en lugar de data */}
          <div className="flex-1 w-0 p-4">
            <div className="flex items-start">
              <div className="flex-shrink-0 pt-0.5">
                <img
                  className="h-10 w-10 rounded-full"
                  src={customData.producto_imagen_url}
                  alt={customData.producto_titulo}
                />
              </div>
              <div className="ml-3 flex-1">
                <p className="text-sm font-medium text-gray-900 flex justify-between select-none">
                  <span>
                    {customData.producto_titulo} - {customData.categoria_nombre}
                  </span>
                  <span className="text-xs text-gray-500">
                    {' '}
                    Se añadió a tu cotización
                  </span>
                </p>
                <div className="mt-1 text-sm text-gray-500 select-none">
                  {customData.producto_descripcion.substring(0, 85) +
                    (customData.producto_descripcion.length > 50 ? '...' : '')}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    });

    const onSubmit = (data) => {
      // Verificar si el producto requiere selección de "tipo" y "banda"
      const requiereSeleccion = caracteristicas.length > 0 && 
        (producto?.subcategoria_nombre === 'Radios Portátiles' || producto?.subcategoria_nombre === 'Radios Móviles') && 
        (caracteristicas[0].es_digital === 'SI' || caracteristicas[0].es_analoga === 'SI');
    
      // Si se requiere selección y no se han seleccionado, mostrar error
      if (requiereSeleccion && (!data.tipo || !data.banda)) {
        toast.error('Por favor, selecciona un tipo y una banda para el producto.');
        return; // Detener la ejecución si la validación falla
      }
    
      // Si pasa la validación o no se requiere, proceder con la lógica de añadir al carrito
      miToastPersonalizado(producto);
      dispatch(addToCart(producto, productoCantidad));
    };
    

  useEffect(() => {
    const cargarProducto = async () => {
      setIsLoading(true);
      const { data, error: fetchError } = await obtenerProductoTitulo(titulo);
      if (fetchError) {
        setIsLoading(false);
        setError(fetchError);
        return;
      }
      if (data) {
        setProducto(data);
        setId(data.producto_id);
      }
      setIsLoading(false);
    };
    cargarProducto();
  }, [titulo]);

  useEffect(() => {
    if (producto) {
      const cargarAccesorios = async () => {
        setIsLoading(true);
        const { data, error: fetchError } = await cargarAccesoriosProducto(id);
        if (fetchError) {
          setIsLoading(false);
          setError(fetchError);
          return;
        }
        if (data) {
          setAccesorios(data);
        }
        setIsLoading(false);
      };
      cargarAccesorios();
    }
  }, [producto, id]);

  useEffect(() => {
    if (producto) {
      const cargarCaracteristicas = async () => {
        setIsLoading(true);
        // eslint-disable-next-line prettier/prettier
        const { data, error: fetchError } = await cargarCaracteristicaProducto(id);
        if (fetchError) {
          setIsLoading(false);
          setError(fetchError);
          return;
        }
        if (data) {
          setCaracteristicas(data);
        }
        setIsLoading(false);
      };
      cargarCaracteristicas();
    }
  }, [producto, id]);

  return (
    <main className="w-full p-0 m-0 flex flex-col items-center border">
      <Toaster />
      {error && (
        <div className="w-full flex justify-center items-center content-center">
          <h2 className="text-2xl font-bold text-red-500">
            Error al cargar el producto
          </h2>
        </div>
      )}
      {!isLoading ? (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:px-20 md:py-4 border min-w-full p-8 md:p-0">
          <nav
            className="flex justify-start col-span-2"
            aria-label="Breadcrumb"
          >
            <ol className="inline-flex items-center space-x-1 md:space-x-3">
              <li className="inline-flex items-center">
                <Link
                  to="/"
                  className="inline-flex items-center text-sm sm:text-lg font-medium text-gray-700 hover:text-green-600"
                >
                  Inicio
                </Link>
              </li>
              <li>
                <div className="flex items-center">
                  <svg
                    aria-hidden="true"
                    className="w-6 h-6 text-gray-400"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <Link
                    to="/productos"
                    className="ml-1 text-sm sm:text-lg font-medium text-gray-700 hover:text-green-600 md:ml-2"
                  >
                    Productos
                  </Link>
                </div>
              </li>
              <li aria-current="page">
                <div className="flex items-center">
                  <svg
                    aria-hidden="true"
                    className="w-6 h-6 text-gray-400"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span className="ml-1 text-sm sm:text-lg font-medium text-gray-500 md:ml-2">
                    {producto?.producto_titulo}
                  </span>
                </div>
              </li>
            </ol>
          </nav>
          {/* Imagen del producto */}
          <div className="col-span-2 md:col-span-1 w-full">
            <div className="col-span-1 border-2 border-gray-100 hover:border-green-900 rounded-md p-4">
              <img
                src={producto?.producto_imagen_url}
                className="w-full h-96 rounded-lg object-contain"
                alt="$product['nombre'] "
                id="productoImagen"
              />
            </div>
          </div>
          {/* Información del producto */}
          <div className="col-span-1 p-4 overflow-hidden">
            <h2
              id="productoNombre"
              className="text-xl font-extrabold md:text-3xl text-green-600"
            >
              {producto?.producto_titulo}
            </h2>
            <h3 id="productoCategoria">{producto?.categoria_nombre}</h3>
            <hr className="my-2 w-full border border-green-800" />
            <div
              id="productoDescripcion"
              className="max-h-32 text-sm sm:text-base text-gray-600 overflow-y-auto scroll-container"
            >
              {/* eslint-disable react/no-danger */}
              <div
                dangerouslySetInnerHTML={{
                  __html: producto?.producto_descripcion,
                }}
              />
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
            {caracteristicas &&
                            caracteristicas.length > 0 &&
                            (producto?.subcategoria_nombre ===
                              'Radios Portátiles' ||
                              producto?.subcategoria_nombre ===
                                'Radios Móviles') &&
                            caracteristicas[0].tiene_vhf === 'SI' && (
              <div className="py-4">
                <div className="flex items-center content-center mb-3">
                  <div className="flex content-center items-center mr-4 w-16">
                    <h3 className="font-bold uppercase">Banda</h3>
                  </div>
                  <ul className="flex">
                    <Controller
                      name="banda"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <>
                          {caracteristicas &&
                            caracteristicas.length > 0 &&
                            (producto?.subcategoria_nombre ===
                              'Radios Portátiles' ||
                              producto?.subcategoria_nombre ===
                                'Radios Móviles') &&
                            caracteristicas[0].tiene_vhf === 'SI' && (
                              <li className="mr-0.5 sm:mr-4">
                                {/* eslint-disable */}
                                <input
                                  {...field}
                                  type="radio"
                                  id="banda-vhf"
                                  value="VHF"
                                  className="hidden peer"
                                />
                                {/* eslint-enable */}
                                <label
                                  htmlFor="banda-vhf"
                                  className="inline-flex items-center justify-between text-gray-500 bg-white border border-gray-200 rounded cursor-pointer peer-checked:border-green-600 hover:text-gray-600 hover:bg-gray-100 peer-checked:text-white peer-checked:bg-green-600"
                                >
                                  <div className="w-full text-sm sm:text-lg font-semibold py-2 px-4">
                                    VHF
                                  </div>
                                  <input
                                    type="radio"
                                    id="banda-vhf"
                                    name="banda"
                                    className="hidden"
                                  />
                                </label>
                              </li>
                            )}
                          {caracteristicas &&
                            caracteristicas.length > 0 &&
                            (producto?.subcategoria_nombre ===
                              'Radios Portátiles' ||
                              producto?.subcategoria_nombre ===
                                'Radios Móviles') &&
                            caracteristicas[0].tiene_uhf === 'SI' && (
                              <li>
                                {/* eslint-disable */}
                                <input
                                  {...field}
                                  type="radio"
                                  id="banda-uhf"
                                  value="UHF"
                                  className="hidden peer"
                                />
                                {/* eslint-enable */}
                                <label
                                  htmlFor="banda-uhf"
                                  className="inline-flex items-center justify-between text-gray-500 bg-white border border-gray-200 rounded cursor-pointer peer-checked:border-green-600 hover:text-gray-600 hover:bg-gray-100 peer-checked:text-white peer-checked:bg-green-600"
                                >
                                  <div className="w-full text-sm sm:text-lg font-semibold py-2 px-4">
                                    UHF
                                  </div>
                                  <input
                                    type="radio"
                                    id="banda-uhf"
                                    name="banda"
                                    className="hidden"
                                  />
                                </label>
                              </li>
                            )}
                        </>
                      )}
                    />
                  </ul>
                </div>
                <div className="flex items-center content-center">
                  <div className="flex content-center items-center mr-4 w-16">
                    <h3 className="font-bold uppercase">Tipo</h3>
                  </div>
                  <ul className="flex">
                    <Controller
                      name="tipo"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <>
                          {caracteristicas &&
                            caracteristicas.length > 0 &&
                            (producto?.subcategoria_nombre ===
                              'Radios Portátiles' ||
                              producto?.subcategoria_nombre ===
                                'Radios Móviles') &&
                            caracteristicas[0].es_digital === 'SI' && (
                              <li className="mr-0.5 sm:mr-4">
                                {/* eslint-disable */}
                                <input
                                  {...field}
                                  type="radio"
                                  id="tipo-digital"
                                  value="Digital"
                                  className="hidden peer"
                                />
                                {/* eslint-enable */}
                                <label
                                  htmlFor="tipo-digital"
                                  className="inline-flex items-center justify-between text-gray-500 bg-white border border-gray-200 rounded cursor-pointer peer-checked:border-green-600 peer-checked:text-white peer-checked:bg-green-600 hover:text-gray-600 hover:bg-gray-100"
                                >
                                  <div className="w-full text-sm sm:text-lg font-semibold py-2 px-4">
                                    Digital
                                  </div>
                                  <input
                                    type="radio"
                                    id="tipo-digital"
                                    name="tipo"
                                    className="hidden"
                                  />
                                </label>
                              </li>
                            )}
                          {caracteristicas &&
                            caracteristicas.length > 0 &&
                            (producto?.subcategoria_nombre ===
                              'Radios Portátiles' ||
                              producto?.subcategoria_nombre ===
                                'Radios Móviles') &&
                            caracteristicas[0].es_analoga === 'SI' && (
                              <li>
                                {/* eslint-disable */}
                                <input
                                  {...field}
                                  type="radio"
                                  id="tipo-analogo"
                                  value="Analogo"
                                  className="hidden peer"
                                />
                                {/* eslint-enable */}
                                <label
                                  htmlFor="tipo-analogo"
                                  className="inline-flex items-center justify-between text-gray-500 bg-white border border-gray-200 rounded cursor-pointer peer-checked:border-green-600 hover:text-gray-600 hover:bg-gray-100 peer-checked:text-white peer-checked:bg-green-600"
                                >
                                  <div className="w-full text-sm sm:text-lg font-semibold py-2 px-4">
                                    Analogo
                                  </div>
                                  <input
                                    type="radio"
                                    id="tipo-analogo"
                                    name="tipo"
                                    className="hidden"
                                  />
                                </label>
                              </li>
                            )}
                        </>
                      )}
                    />
                  </ul>
                </div>
              </div>
              )}
              <div className="w-full py-2 grid grid-cols-4 sm:grid-cols-5 gap-2">
                <Controller
                  name="productoCantidad"
                  control={control}
                  defaultValue={productoCantidad}
                  render={({ field }) => (
                    <div className="col-span-4 sm:col-span-1 border border-gray-200 grid grid-cols-3 rounded overflow-hidden">
                      {/* eslint-disable */}
                      <input
                        {...field}
                        type="number"
                        className="rounded p-0 sin-spinner w-full border-0 ring-0 hover:ring-0 active:ring-0 focus:ring-0 px-3 col-span-2"
                        min="1"
                      />
                      {/* eslint-enable */}
                      <div className="col-span-1">
                        <button
                          type="button"
                          onClick={increment}
                          className="w-full bg-green-600 text-white border border-white rounded-tr hover:scale-95 transition"
                        >
                          +
                        </button>
                        <button
                          type="button"
                          onClick={decrement}
                          className="w-full bg-green-600 text-white border border-white rounded-br hover:scale-95 transition"
                        >
                          -
                        </button>
                      </div>
                    </div>
                  )}
                />
                <button
                  className="bg-green-600 p-2 rounded font-bold text-white text-xs md:text-[15px] flex justify-center items-center border-2 border-green-700 hover:border-green-100 col-span-2"
                  type="submit"
                >
                  COTIZAR PRODUCTO
                </button>
                {producto?.subcategoria_nombre !== 'Accesorios' && (
                  <a
                    href={producto?.producto_pdf_url}
                    target="_blank"
                    rel="noreferrer"
                    className="bg-green-100 p-2 rounded color-azul-3 font-bold text-xs md:text-[15px] flex justify-center items-center sm:mr-2 border-2 border-green-100 hover:border-green-800 col-span-2"
                  >
                    VER FICHA TÉCNICA
                  </a>
                )}
              </div>
            </form>
            {producto?.subcategoria_nombre !== 'Accesorios' &&
              accesorios &&
              accesorios.length > 0 && (
                <div className="py-2">
                  <h2 className="font-bold mb-1">
                    Accesorios para el {producto?.producto_titulo}
                  </h2>
                  <p className="text-sm mb-2 text-gray-600">
                    Aprovecha al máximo el {producto?.producto_titulo} mediante nuestros <strong>componentes personalizados</strong>. Cada componente adicional potencia tus posibilidades de interacción.
                  </p>
                  <div className="pb-10 grid grid-cols-3 gap-0 space-x-2 overflow-x-auto scroll-container">
                    {accesorios &&
                      accesorios.length > 0 &&
                      accesorios.map((accesorio) => (
                        <Link
                          to={{
                            pathname: `/producto/${accesorio.informacion_accesorio.titulo}`,
                            state: { id: accesorio.informacion_accesorio.id },
                          }}
                          onClick={() =>
                            localStorage.setItem(
                              'productoId',
                              accesorio.informacion_accesorio.id,
                            )
                          }
                          className="relative flex flex-col items-center justify-center rounded border transition duration-500 mr-2 hover:border-green-900 overflow-hidden"
                          key={accesorio.id}
                        >
                          <div className="w-20 h-20 overflow-hidden">
                            <img
                              className="w-full h-full object-contain"
                              src={accesorio.informacion_accesorio.imagen_url}
                              alt={accesorio.informacion_accesorio.titulo}
                            />
                          </div>
                          <div className="bg-green-100 w-full flex justify-center items-center overflow-hidden">
                            <h2
                              title={accesorio.informacion_accesorio.titulo}
                              className="z-20 w-3/4 text-green-600 text-center py-2 font-semibold truncate min-w-0"
                            >
                              {accesorio.informacion_accesorio.titulo}
                            </h2>
                          </div>
                        </Link>
                      ))}
                  </div>
                </div>
              )}
          </div>
        </div>
      ) : (
        <div className="w-full h-full flex justify-center items-center content-center">
          <LoaderProducto />
        </div>
      )}
    </main>
  );
}

export default InformacionProducto;
