import React, { useState } from 'react';

function FormularioContacto() {
  const [status, setStatus] = useState('');
  const [formData, setFormData] = useState({
    nombreEmpresa: '',
    telefono: '',
    correo: '',
    mensaje: '',
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch('https://formspree.io/f/xkndelng', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      const responseData = await response.json();
      if (response.ok) {
        setStatus('Gracias por contactarnos, te responderemos a la brevedad');
      } else {
        setStatus(
          `Hubo un error, por favor intenta nuevamente: ${responseData.message}`,
        );
      }
    } catch (error) {
      setStatus(
        `Hubo un error, por favor intenta nuevamente: ${error.message}`,
      );
    }
  };

  return (
    <form id="formulario-contacto" onSubmit={handleSubmit}>
      {/* Nombre de la empresa */}
      <div className="relative mb-2">
        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-5 h-5 text-gray-500"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008z"
            />
          </svg>
        </div>
        <input
          onChange={handleChange}
          type="text"
          name="nombreEmpresa"
          id="nombreEmpresa"
          placeholder="Nombre de la empresa (Obligatorio)"
          className="w-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-[#00BEE7] focus:border-transparent pl-10 rounded hover:border-blue-900"
          required
        />
      </div>
      {/* Envolvemos el input de telefono y correo en un div para que se muestren en una sola linea */}
      <div className="flex flex-col sm:flex-row justify-between content-center items-center">
        {/* Telefono */}
        <div className="relative mb-2 sm:mr-2 w-full sm:w-1/2">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-5 h-5 text-gray-500"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M10.5 1.5H8.25A2.25 2.25 0 006 3.75v16.5a2.25 2.25 0 002.25 2.25h7.5A2.25 2.25 0 0018 20.25V3.75a2.25 2.25 0 00-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3"
              />
            </svg>
          </div>
          <input
            onChange={handleChange}
            type="text"
            name="telefono"
            id="telefono"
            placeholder="Telefono (Obligatorio)"
            className="border border-gray-300 focus:outline-none focus:ring-2 focus:ring-[#00BEE7] focus:border-transparent pl-10 w-full rounded hover:border-blue-900"
            required
          />
        </div>
        {/* Correo */}
        <div className="relative mb-2 w-full sm:w-1/2">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-5 h-5 text-gray-500"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
              />
            </svg>
          </div>
          <input
            onChange={handleChange}
            type="email"
            name="correo"
            id="correo"
            placeholder="Correo (Obligatorio)"
            className="border border-gray-300 focus:outline-none focus:ring-2 focus:ring-[#00BEE7] focus:border-transparent pl-10 w-full rounded hover:border-blue-900"
            required
          />
        </div>
      </div>
      {/* Mensaje adicional */}
      <div className="relative mb-2">
        <div className="absolute left-0 top-2 flex items-center pl-3 pointer-events-none">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-5 h-5 text-gray-500"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"
            />
          </svg>
        </div>
        <textarea
          onChange={handleChange}
          name="mensaje"
          id="mensaje"
          placeholder="Mensaje adicional"
          className="w-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-[#00BEE7] focus:border-transparent pl-10 rounded hover:border-blue-900 pt-1"
          rows="6"
        />
      </div>
      {/* Boton de enviar */}
      <button className="continue-application" type="submit">
        <div>
          <div className="pencil" />
          <div className="folder">
            <div className="top">
              <svg viewBox="0 0 24 27">
                <path d="M1,0 L23,0 C23.5522847,-1.01453063e-16 24,0.44771525 24,1 L24,8.17157288 C24,8.70200585 23.7892863,9.21071368 23.4142136,9.58578644 L20.5857864,12.4142136 C20.2107137,12.7892863 20,13.2979941 20,13.8284271 L20,26 C20,26.5522847 19.5522847,27 19,27 L1,27 C0.44771525,27 6.76353751e-17,26.5522847 0,26 L0,1 C-6.76353751e-17,0.44771525 0.44771525,1.01453063e-16 1,0 Z" />
              </svg>
            </div>
            <div className="paper" />
          </div>
        </div>
        <span className="font-bold">CONTACTAR</span>
      </button>
      <p>{status}</p>
    </form>
  );
}

export default FormularioContacto;
