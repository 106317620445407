import React from 'react';
import CarruselProductos from '../components/inicio/CarruselProductos';
import BannerNuevo from '../components/inicio/BannerNuevo';

function Inicio() {
  return (
    <div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 relative overflow-hidden">
        <div className="col-span-2 relative z-10">
          <BannerNuevo />
        </div>
        <div className="col-span-2">
          <CarruselProductos
            tipo="Motorola"
            titulo="VER EQUIPOS MOTOROLA"
            tituloBoton="PRODUCTOS MOTOROLA"
          />
        </div>
        <div className="col-span-2">
          <CarruselProductos
            tipo="Hytera"
            titulo="VER EQUIPOS HYTERA"
            tituloBoton="PRODUCTOS HYTERA"
          />
        </div>
        <img src="https://i.ibb.co/1vG2PNX/Historia-de-Instagram-fondo-para-redes-sociales-orga-nico-simple-rosa-y-beige.png" alt="imagen"  className='absolute inset-0 z-0 w-full h-full object-cover object-left-bottom hover:translate-x-4 transition duration-500 ease-in-out' />
      </div>
    </div>
  );
}

export default Inicio;
