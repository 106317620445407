import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faChevronUp,
  faCircleArrowRight,
} from '@fortawesome/free-solid-svg-icons';

function NavMovil() {
  // Array de links
  const links = [
    { name: 'Inicio', path: '/' },
    { name: 'Productos', path: '/productos' },
    {
      name: 'Nosotros',
      sublinks: [
        { name: 'Quienes somos', path: '/nosotros' },
        { name: 'Contacto', path: '/contacto' },
      ],
    },
  ];

  const [openMenuIndex, setOpenMenuIndex] = useState(null);
  const ref = useRef(null);

  const handleClickOutside = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      setOpenMenuIndex(null);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleMenu = (index) => {
    setOpenMenuIndex(openMenuIndex === index ? null : index);
  };
  return (
    <div ref={ref}>
      <ul className="flex flex-col font-semibold text-base px-1">
        {links.map((link, index) => (
          <li key={link.path} className="flex flex-col text-green-600 my-1">
            {link.sublinks ? (
              <>
                <button
                  className={`flex justify-between items-center bg-gray-50 content-center text-green-600 py-1 px-2 uppercase ${
                    openMenuIndex === index ? 'bg-blue-500' : ''
                  }`}
                  onClick={() => toggleMenu(index)}
                  type="button"
                >
                  {link.name}
                  <FontAwesomeIcon
                    icon={openMenuIndex === index ? faChevronUp : faChevronDown}
                    className={`text-base ${
                      openMenuIndex === index ? 'text-white' : 'text-green-600'
                    }`}
                  />
                </button>
                {openMenuIndex === index && (
                  <ul className="flex flex-col font-semibold text-base shadow rounded-b">
                    {link.sublinks.map((sublink) => (
                      <li
                        key={sublink.path}
                        className="py-1 flex justify-start"
                      >
                        <Link
                          to={sublink.path}
                          className="flex w-full justify-start items-start content-start text-green-600 p-1"
                        >
                          <FontAwesomeIcon
                            icon={faCircleArrowRight}
                            className="text-white text-xl mr-3"
                          />
                          {sublink.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
              </>
            ) : (
              <Link
                to={link.path}
                className="flex justify-between items-center content-center text-green-600 bg-gray-50 rounded py-1 px-2 uppercase"
              >
                {link.name}
              </Link>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default NavMovil;
