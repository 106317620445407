import React, { useState } from 'react';
import { useSelector } from 'react-redux';

function FormularioCotizacion() {
  const productosCarrito = useSelector((state) => state.cart);
  const [enviado, setEnviado] = useState(null);
  const [message, setMessage] = useState('');
  const [formData, setFormData] = useState({
    nombreEmpresa: '',
    personaContacto: '',
    rutEmpresa: '',
    telefono: '',
    correo: '',
    faenaProyecto: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Obtener y parsear los datos de la cotización del Local Storage
    const parsedData = productosCarrito.cart;

    if (!parsedData || parsedData.length === 0) {
      setMessage('El carrito está vacío');
      return;
    }

    // Construir la tabla de cotización
    let cotizacionTable = `
      <table style="width: 100%; border-collapse: collapse;">
        <thead style="background-color: #f3f4f6;">
          <tr>
            <th style="padding: 1em; text-align: left;">Imagen</th>
            <th style="padding: 1em; text-align: left;">Nombre</th>
            <th style="padding: 1em; text-align: left;">Cantidad</th>
            <th style="padding: 1em; text-align: left;">Categoria</th>
          </tr>
        </thead>
        <tbody>
    `;

    if (parsedData) {
      parsedData.forEach((item) => {
        cotizacionTable += `
          <tr style="border: 1px solid #e5e7eb;">
            <td style="padding: 0.5em;"><img src="${item.product.producto_imagen_url}" alt="${item.product.producto_titulo}" style="max-width: 100px;"></td>
            <td style="padding: 0.5em; text-align: left;">${item.product.producto_titulo}</td>
            <td style="padding: 0.5em; text-align: left;">${item.quantity}</td>
            <td style="padding: 0.5em; text-align: left;">${item.product.categoria_nombre}</td>
          </tr>
        `;
      });
    }

    cotizacionTable += '</tbody></table>';

    // Construir el contenido del correo electrónico
    const emailContent = `
      <h2 style="font-size: 1.5em; color: #1f2937;">Datos del cliente:</h2>
      <ul style="list-style: none; padding: 0;">
        <li><strong>Nombre de la empresa:</strong> ${formData.nombreEmpresa}</li>
        <li><strong>Persona de contacto:</strong> ${formData.personaContacto}</li>
        <li><strong>Rut empresa:</strong> ${formData.rutEmpresa}</li>
        <li><strong>Teléfono:</strong> ${formData.telefono}</li>
        <li><strong>Correo:</strong> ${formData.correo}</li>
        <li><strong>Faena o proyecto:</strong> ${formData.faenaProyecto}</li>
        <li><strong>Mensaje adicional:</strong> ${formData.mensaje}</li>
      </ul>
      <h2 style="font-size: 1.5em; color: #1f2937;">Productos:</h2>
      ${cotizacionTable}
    `;

    // Datos para el envío del correo
    const emailData = {
      sender: { name: formData.nombreEmpresa, email: formData.correo },
      to: [{ email: 'contacto@atst.cl' }],
      subject: 'Cotización de Productos',
      htmlContent: emailContent,
    };

    try {
      const response = await fetch('https://api.sendinblue.com/v3/smtp/email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'api-key':
            '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',
        },
        body: JSON.stringify(emailData),
      });

      if (response.ok) {
        setEnviado(true);
        // Puedes resetear el formulario si lo deseas aquí
        setFormData({
          nombreEmpresa: '',
          personaContacto: '',
          rutEmpresa: '',
          telefono: '',
          correo: '',
          faenaProyecto: '',
        });
      } else {
        setEnviado(false);
      }
    } catch (error) {
      setEnviado(false);
      setMessage(error.message);
    }
  };

  const renderAlert = () => {
    if (enviado === true) {
      return (
        <div
          className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mt-5"
          role="alert"
        >
          <strong className="font-bold">
            ¡Gracias por cotizar con nosotros!
          </strong>
          <span className="block sm:inline">
            Te contactaremos a la brevedad.
          </span>
        </div>
      );
    }

    if (enviado === false) {
      return (
        <div
          className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mt-5"
          role="alert"
        >
          <strong className="font-bold">
            ¡Oops! Hubo un problema al enviar el formulario: {message}
          </strong>
          <span className="block sm:inline">
            Por favor, intentalo de nuevo.
          </span>
        </div>
      );
    }

    return null;
  };

  return (
    <form onSubmit={handleSubmit}>
      {/* Nombre de la empresa */}
      <div className="relative mb-2">
        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-5 h-5 text-gray-500"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008z"
            />
          </svg>
        </div>
        <input
          onChange={handleChange}
          type="text"
          name="nombreEmpresa"
          id="nombreEmpresa"
          placeholder="Nombre de la empresa (Obligatorio)"
          className="w-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-[#00BEE7] focus:border-transparent pl-10 rounded hover:border-blue-900"
          required
        />
      </div>
      {/* Envolvemos el input de persona de contacto y rut empresa en un div para que se muestren en una sola linea */}
      <div className="flex flex-col sm:flex-row justify-between content-center items-center">
        {/* Persona de contacto */}
        <div className="relative mb-2 sm:mr-2 w-full sm:w-1/2">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-5 h-5 text-gray-500"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
              />
            </svg>
          </div>
          <input
            onChange={handleChange}
            type="text"
            name="personaContacto"
            id="personaContacto"
            placeholder="Persona de contacto (Obligatorio)"
            className="border border-gray-300 focus:outline-none focus:ring-2 focus:ring-[#00BEE7] focus:border-transparent pl-10 w-full rounded hover:border-blue-900"
            required
          />
        </div>
        {/* Rut empresa */}
        <div className="relative mb-2 w-full sm:w-1/2">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-5 h-5 text-gray-500"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5zm6-10.125a1.875 1.875 0 11-3.75 0 1.875 1.875 0 013.75 0zm1.294 6.336a6.721 6.721 0 01-3.17.789 6.721 6.721 0 01-3.168-.789 3.376 3.376 0 016.338 0z"
              />
            </svg>
          </div>
          <input
            onChange={handleChange}
            type="text"
            name="rutEmpresa"
            id="rutEmpresa"
            placeholder="Rut empresa (Obligatorio)"
            className="border border-gray-300 focus:outline-none focus:ring-2 focus:ring-[#00BEE7] focus:border-transparent pl-10 w-full rounded hover:border-blue-900"
            required
          />
        </div>
      </div>
      {/* Envolvemos el input de telefono y correo en un div para que se muestren en una sola linea */}
      <div className="flex flex-col sm:flex-row justify-between content-center items-center">
        {/* Telefono */}
        <div className="relative mb-2 sm:mr-2 w-full sm:w-1/2">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-5 h-5 text-gray-500"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M10.5 1.5H8.25A2.25 2.25 0 006 3.75v16.5a2.25 2.25 0 002.25 2.25h7.5A2.25 2.25 0 0018 20.25V3.75a2.25 2.25 0 00-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3"
              />
            </svg>
          </div>
          <input
            onChange={handleChange}
            type="text"
            name="telefono"
            id="telefono"
            placeholder="Telefono (Obligatorio)"
            className="border border-gray-300 focus:outline-none focus:ring-2 focus:ring-[#00BEE7] focus:border-transparent pl-10 w-full rounded hover:border-blue-900"
            required
          />
        </div>
        {/* Correo */}
        <div className="relative mb-2 w-full sm:w-1/2">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-5 h-5 text-gray-500"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
              />
            </svg>
          </div>
          <input
            onChange={handleChange}
            type="email"
            name="correo"
            id="correo"
            placeholder="Correo (Obligatorio)"
            className="border border-gray-300 focus:outline-none focus:ring-2 focus:ring-[#00BEE7] focus:border-transparent pl-10 w-full rounded hover:border-blue-900"
            required
          />
        </div>
      </div>
      {/* Faena o proyecto */}
      <div className="relative mb-2">
        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-5 h-5 text-gray-500"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M2.25 7.125C2.25 6.504 2.754 6 3.375 6h6c.621 0 1.125.504 1.125 1.125v3.75c0 .621-.504 1.125-1.125 1.125h-6a1.125 1.125 0 01-1.125-1.125v-3.75zM14.25 8.625c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v8.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 01-1.125-1.125v-8.25zM3.75 16.125c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v2.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 01-1.125-1.125v-2.25z"
            />
          </svg>
        </div>
        <input
          onChange={handleChange}
          type="text"
          name="faenaProyecto"
          id="faenaProyecto"
          placeholder="Faena o proyecto (Obligatorio)"
          className="w-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-[#00BEE7] focus:border-transparent pl-10 rounded hover:border-blue-900"
          required
        />
      </div>
      {/* Mensaje adicional */}
      <div className="relative mb-2">
        <div className="absolute left-0 top-2 flex items-center pl-3 pointer-events-none">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-5 h-5 text-gray-500"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"
            />
          </svg>
        </div>
        <textarea
          onChange={handleChange}
          name="mensaje"
          id="mensaje"
          placeholder="Mensaje adicional"
          className="w-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-[#00BEE7] focus:border-transparent pl-10 rounded hover:border-blue-900 pt-1"
          rows="6"
        />
      </div>
      {/* Boton de enviar */}
      <button className="continue-application" type="submit">
        <div>
          <div className="pencil" />
          <div className="folder">
            <div className="top">
              <svg viewBox="0 0 24 27">
                <path d="M1,0 L23,0 C23.5522847,-1.01453063e-16 24,0.44771525 24,1 L24,8.17157288 C24,8.70200585 23.7892863,9.21071368 23.4142136,9.58578644 L20.5857864,12.4142136 C20.2107137,12.7892863 20,13.2979941 20,13.8284271 L20,26 C20,26.5522847 19.5522847,27 19,27 L1,27 C0.44771525,27 6.76353751e-17,26.5522847 0,26 L0,1 C-6.76353751e-17,0.44771525 0.44771525,1.01453063e-16 1,0 Z" />
              </svg>
            </div>
            <div className="paper" />
          </div>
        </div>
        <span className="font-bold">REALIZAR COTIZACIÓN</span>
      </button>
      {/* Mensaje de envio exitoso o fallido */}
      {renderAlert()}
    </form>
  );
}

export default FormularioCotizacion;
