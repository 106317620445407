import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt, faMailBulk } from '@fortawesome/free-solid-svg-icons';

function Footer() {
  return (
    <footer className="w-full fondo-categoria-enlaces-inalambricos relative">
      <div className="w-full grid grid-cols-1 md:grid-cols-6 z-30 px-10 py-8 gap-2 md:gap-6">
        <div className="z-20 col-span-2 md:col-span-1 w-full">
          <div className="mapa relative h-56 w-full overflow-hidden border rounded-lg shadow-2xl">
            <iframe
              title="Mapa de Google de una ubicación específica"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2364.269770326839!2d-70.56576781928655!3d-33.4075906636294!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662cedcfd4b7adf%3A0xffe301ec35abcdfa!2sAv.%20Apoquindo%206410%2C%20Oficina%20507%2C%208320000%20Las%20Condes%2C%20Regi%C3%B3n%20Metropolitana!5e0!3m2!1ses-419!2scl!4v1706812148519!5m2!1ses-419!2scl"
              style={{ border: '0', position: 'absolute' }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              className="w-full h-full"
            />
          </div>
        </div>
        <div className="flex flex-col items-start justify-center z-20 col-span-2 md:mb-0">
          <div className="h-20 hidden md:block">
            <Link to="/">
              <img
                src="https://dps2wxsijib8x.cloudfront.net/atst/ATST-BLANCO.png"
                alt="Logo Andes Tec"
                className="object-contain w-full h-full"
              />
            </Link>
          </div>
          <div className="pl-0">
            <p className="text-center md:text-start text-gray-100 text-sm md:text-base">
            ATST es líder en brindar soluciones integrales de radiocomunicación en Chile, enfocándose en la innovación y eficiencia para mejorar la conectividad y comunicación de las empresas.            </p>
          </div>
          {/* Datos de contacto correo y telefono */}
        </div>
        <div className="col-span-1"> </div>
        <div className="z-20 md:mb-0 hidden md:block">
          <div className="h-8 md:h-20 flex items-center">
            <h3 className="text-start text-white text-lg font-bold">
              Productos
            </h3>
          </div>
          <ul>
            <Link
              to="/productos"
              className="text-start block text-gray-100 text-base hover:text-gray-200 transition-colors duration-300 ease-in-out "
            >
              <a href="https://www.andes-tec.cl/productos/motorola">Motorola</a>
            </Link>
            <Link
              to="/productos"
              className="text-start block text-gray-100 text-base hover:text-gray-200 transition-colors duration-300 ease-in-out "
            >
              <a href="https://www.andes-tec.cl/productos/hytera">Hytera</a>
            </Link>
            <Link
              to="/productos"
              className="text-start block text-gray-100 text-base hover:text-gray-200 transition-colors duration-300 ease-in-out "
            >
              <a href="https://www.andes-tec.cl/productos/kenwood">Kenwood</a>
            </Link>
          </ul>
        </div>
        <div className="z-20 col-span-2 md:col-span-1">
          <div className="h-8 md:h-20 hidden md:flex items-center">
            <h3 className="text-start text-white text-lg font-bold">
              Contacto
            </h3>
          </div>
          <ul>
            <div className="pl-0 flex flex-col space-y-3 justify-between items-center w-full pr-4 md:pt-3">
              <div className="flex items-center content-center">
                <FontAwesomeIcon
                  icon={faPhoneAlt}
                  className="text-xl text-white hover:text-gray-100 transition-colors duration-300 ease-in-out mr-2"
                />
                <p className="text-start text-gray-100 text-base">
                  +569 3388 3163
                </p>
              </div>
              <div className="flex items-center content-center">
                <FontAwesomeIcon
                  icon={faMailBulk}
                  className="text-xl text-white hover:text-gray-100 transition-colors duration-300 ease-in-out mr-2"
                />
                <p className="text-start text-gray-100 text-base">
                contacto@atst.cl
                </p>
              </div>
            </div>
          </ul>
        </div>
      </div>
      <div className="w-full relative">
        {/* derechos reservados */}
        <div className="w-full h-10 flex items-center justify-center px-4">
          <p className="text-gray-100 text-xs md:text-base z-20 text-center">
            ©2024 ATST RADIOCOMUNICACIONES. Todos los derechos reservados.
          </p>
        </div>
        <div className="absolute top-0 left-0 right-0 bottom-0 z-0 bg-blue-950 opacity-50" />
      </div>
      <div className="bg-blue-950 opacity-80 absolute top-0 left-0 right-0 bottom-0 z-10" />
      <img
        src="https://dps2wxsijib8x.cloudfront.net/atst/FooterBanner.png"
        alt="Logo Andes Tec"
        className="object-cover w-full h-full absolute top-0 left-0 right-0 bottom-0 z-0"
      />
    </footer>
  );
}

export default Footer;
