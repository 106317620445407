import React from 'react';
import PropTypes from 'prop-types';

export default function LoaderProductos({ key, vista }) {
  return vista === 'grid' ? (
    <div
      key={key}
      role="status"
      className="group flex flex-col relative w-full mb-4 transition rounded-md overflow-hidden items-center content-center animate-pulse"
    >
      <div className="flex items-center justify-center w-full h-40 bg-gray-200 rounded sm:w-96">
        <svg
          className="w-10 h-10 text-gray-200"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 20 18"
        >
          <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
        </svg>
      </div>
      <div className="w-full h-10 flex justify-center items-center content-center bg-gray-300 rounded-b">
        <h5 className="text-base font-extrabold uppercase text-white">
          Cargando...
        </h5>
      </div>
    </div>
  ) : (
    <div
      key={key}
      role="status"
      className="grid grid-cols-5 h-40 border rounded shadow overflow-hidden animate-pulse"
    >
      <div className="col-span-1 w-full h-40 bg-gray-200 p-2">
        <svg
          className="w-10 h-10 text-gray-200"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 20 18"
        >
          <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
        </svg>
      </div>
      <div className="col-span-4 p-4">
        <div className="flex justify-between items-center content-center mb-2">
          <p className="text-gray-700 font-bold text-lg flex items-center content-center bg-gray-300 rounded w-1/5 h-5" />
          <p className="text-gray-500 text-sm bg-gray-300 rounded w-1/12 h-3" />
        </div>
        <hr className="border border-gray-300 my-1" />
        <div className="h-20 overflow-y-auto overflow-x-hidden scroll-container mt-2">
          <p className="text-shadow bg-gray-300 rounded text-base h-2 w-3/4" />
          <p className="text-shadow bg-gray-300 rounded text-base h-2 w-4/6 mt-1" />
          <p className="text-shadow bg-gray-300 rounded text-base h-2 w-5/6 mt-1" />
          <p className="text-shadow bg-gray-300 rounded text-base h-2 w-3/4 mt-1" />
          <p className="text-shadow bg-gray-300 rounded text-base h-2 w-4/6 mt-1" />
        </div>
      </div>
    </div>
  );
}

LoaderProductos.propTypes = {
  key: PropTypes.number.isRequired,
  vista: PropTypes.string.isRequired,
};
