import { createClient } from '@supabase/supabase-js';

const supabase = createClient(
  'https://wlpplgpeaewfryvssxtm.supabase.co',
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6IndscHBsZ3BlYWV3ZnJ5dnNzeHRtIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTQxOTY3ODIsImV4cCI6MjAwOTc3Mjc4Mn0.rWzWFGDKp1LOeH3gvGkburHWe3ksF4cL1tOXclEazDc',
);

// Obtener todos los productos de una subcategoría
export const cargarProductosSubcategoria = async (
  inicio,
  fin,
  subcategoriaIds,
) => {
  const { data, error } = await supabase.rpc(
    'obtener_productos_por_multiples_subcategorias',
    {
      limite_inicio: inicio,
      limite_fin: fin + 1,
      subcategoria_ids: subcategoriaIds,
    },
  );

  if (error) {
    return { data: null, error };
  }
  return { data, error: null };
};

// Obtener un producto por la ID mediante rpc
export const obtenerProductoID = async (id) => {
  const { data, error } = await supabase.rpc('obtener_producto_por_id', {
    producto_id_input: id,
  });

  if (error) {
    return { data: null, error };
  }
  return { data, error: null };
};

// Obtener un producto por el titulo mediante rpc
export const obtenerProductoTitulo = async (titulo) => {
  const { data, error } = await supabase.rpc('obtener_producto_por_titulo', {
    producto_titulo_input: titulo,
  });

  if (error) {
    return { data: null, error };
  }
  return { data, error: null };
};

// Obtener todos los productos gradualmente
export const cargarTodosLosProductos = async (inicio, fin) => {
  const { data: productos, error } = await supabase
    .from('productos')
    .select(
      `
        *,
        subcategoria:subcategorias(
            *,
            categoria: categorias(*)
        )
    `,
    )
    .range(inicio, fin)
    .order('estado', { ascending: false })
    .order('visitas', { ascending: false });

  if (error) {
    return { data: null, error };
  }
  return { data: productos, error: null };
};

// Obtener los productos mas populares
export const cargarProductosPopulares = async (inicio, fin) => {
  const { data: productos, error } = await supabase
    .from('productos')
    .select(
      `
        *,
        subcategoria:subcategorias(
            *,
            categoria: categorias(*)
        )
    `,
    )
    .range(inicio, fin)
    .order('estado', { ascending: false })
    .order('visitas', { ascending: false });

  if (error) {
    return { data: null, error };
  }
  return { data: productos, error: null };
};

// Obtener los productos más recientes con toda la información, incluyendo subcategorías y categorías
export const cargarProductosRecientes = async (inicio, fin) => {
  const { data: productos, error } = await supabase
    .from('productos')
    .select(
      `
        *,
        subcategoria:subcategorias(
            *,
            categoria: categorias(*)
        )
    `,
    )
    .range(inicio, fin)
    .order('fecha_de_adicion', { ascending: false })
    .order('estado', { ascending: false })
    .order('visitas', { ascending: false });

  if (error) {
    return { data: null, error };
  }
  return { data: productos, error: null };
};

// Obtener los productos por busqueda de titulo
export const buscarTituloCompleto = async (buscar) => {
  const { data, error } = await supabase.rpc('buscar_productos_por_titulo', {
    termino_busqueda: buscar,
  });

  if (error) {
    return { data: null, error };
  }
  return { data, error: null };
};

export async function cargarProductosPorMarca(marca, limit = 10, offset = 0) {
  const marcaConMayuscula = marca.charAt(0).toUpperCase() + marca.slice(1)

  const { data, error, count } = await supabase
    .from('productos')
    .select(`
      *,
      subcategorias (
        *,
        categorias (
          nombre
        )
      )
    `, { count: 'exact' }) // Solicitar el conteo exacto de filas
    .eq('subcategorias.categorias.nombre', marcaConMayuscula)
    .order('estado', { ascending: false })
    .order('visitas', { ascending: false })
    .range(offset, offset + limit - 1)

  if (error) {
    console.error('Error al obtener productos:', error)
    throw new Error('Error al obtener productos')
  }
  
  console.log('Productos obtenidos:', data.length)
  return { data: data || [], count: count || 0 }
}

// Obtener la caracteristica de un producto
export const cargarCaracteristicaProducto = async (id) => {
  const { data: caracteristicas, error } = await supabase
    .from('caracteristicas')
    .select(
      `
        *
    `,
    )
    .eq('producto_id', id);

  if (error) {
    return { data: null, error };
  }

  return { data: caracteristicas, error: null };
};

// Obtener accesorios de un producto
export const cargarAccesoriosProducto = async (id) => {
  const { data: accesorios, error } = await supabase
    .from('accesorios')
    .select(
      `
        *,
        informacion_accesorio:productos!accesorios_id_accesorio_fkey(*)
    `,
    )
    .eq('id_producto', id);

  if (error) {
    return { data: null, error };
  }

  return { data: accesorios, error: null };
};
